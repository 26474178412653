import React from "react";
import {
  Button,
  Divider,
  FormControl,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createLocation } from "../../../actions/packageActions";
import "./AddLocation.css";

const AddLocation = ({ handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const handleAddLocation = async (e) => {
    e.preventDefault();

    dispatch(createLocation({ city: city }, navigate));
    handleClose();
  };
  const [city, setCity] = React.useState("");
  return (
    <Stack p={4} spacing={matches ? 3 : 1}>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", fontSize: "1.4rem", fontWeight: "700" }}
      >
        Add Location
      </Typography>
      <Divider />
      <Stack>
        <FormControl>
          <Stack spacing={4}>
            <TextField
              size={matches ? "medium" : "small"}
              label="Location"
              variant="outlined"
              placeholder="Enter the Location"
              onChange={(e) => setCity(e.target.value)}
            />

            <Button
              disabled={city === ""}
              variant="contained"
              onClick={handleAddLocation}
            >
              Submit
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
    // <div className="add_package">
    //   <CustomLink title={"Back to Manage Location"} />
    //   <FormHeading>Add New Location</FormHeading>
    //   <form onSubmit={handleAddLocation}>
    //     <input
    //       type="text"
    //       name="city"
    //       placeholder="Location Name"
    //       onChange={(e) => setCity(e.target.value)}
    //     />
    //     <button type="submit" className="add_package_btn">
    //       Add Location
    //     </button>
    //   </form>
    // </div>
  );
};

export default AddLocation;
