import React from "react";
import {
  Button,
  Divider,
  FormControl,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createComplaint } from "../../../actions/userActions";

const AddComplaint = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const handleAddComplaint = async (e) => {
    e.preventDefault();
    console.log(data);
    dispatch(createComplaint(data, navigate));
  };
  const [data, setData] = React.useState({
    customer: "",
    number: "",
    complaint: "",
  });
  return (
    <Stack p={4} spacing={matches ? 3 : 1}>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", fontSize: "1.4rem", fontWeight: "700" }}
      >
        Add Complaint
      </Typography>
      <Divider />
      <Stack>
        <FormControl>
          <Stack spacing={4}>
            <TextField
              size={matches ? "medium" : "small"}
              label="Customer"
              variant="outlined"
              placeholder="Enter the Customer Name"
              onChange={(e) => setData({ ...data, customer: e.target.value })}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Mobile Number"
              variant="outlined"
              type="number"
              placeholder="Enter the Mobile Number"
              onChange={(e) => setData({ ...data, number: e.target.value })}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Complaint"
              variant="outlined"
              type={"number"}
              placeholder="Enter the Complaint"
              onChange={(e) => setData({ ...data, complaint: e.target.value })}
            />

            <Button
              disabled={
                data.customer === "" ||
                data.number === "" ||
                data.complaint === ""
              }
              variant="contained"
              onClick={handleAddComplaint}
            >
              Submit
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default AddComplaint;
