import { filter } from "lodash";
import { useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Backdrop,
  Modal,
  Fade,
  Box,
  useMediaQuery,
  Divider,
  Slide,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  styled,
  alpha,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, NavigateNext, Search } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import { useDispatch, useSelector } from "react-redux";
// import AddComplaint from "./AddComplaint";
import { getComplaints } from "../../../actions/userActions";
import { getPackages } from "../../../actions/packageActions";
import { API } from "../../../api";
import { editScrollMenu } from "../../../actions/appearanceActions";

// ----------------------------------------------------------------------

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 45,
  margin: 20,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", align: "center" },
  { id: "present", label: "Present", align: "center" },
  { id: "action", label: "Action", align: "center" },
];

// ----------------------------------------------------------------------

export default function Labpackages({ menu }) {
  const isMobile = useMediaQuery("(max-width:550px)");
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    dispatch(getComplaints());
  }, [dispatch]);

  const { isLoading, packageList } = useSelector((state) => state.packages);
  const [menuData, setMenuData] = useState({});
  const fun = async () => {
    const { data } = await API.get(`/appearance/scrollmenu/${menu._id}`);
    setMenuData(data);
  };
  useEffect(() => {
    fun();
    dispatch(getPackages());
  }, [dispatch]);

  const menu_packages = menuData.packages || [];
  const menu_packages_list = menu_packages?.map((d) => d.packageId) || [];

  const remaining_packages = packageList?.filter(
    (p) => !menu_packages_list?.includes(p._id)
  );

  const [page, setPage] = useState(0);

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleEditTest = async (e, data) => {
    e.preventDefault();
    setDisable(true);
    dispatch(editScrollMenu(data, navigate));

    setTimeout(() => {
      fun();
      dispatch(getPackages());
    }, 500);
    setDisable(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage -
            [...menu_packages, ...remaining_packages].length
        )
      : 0;

  const isNotFound =
    ![...menu_packages, ...remaining_packages]?.length && !!filterName;

  // name

  const navigate = useNavigate();

  return !isLoading ? (
    <>
      <Box sx={{ m: 0, p: 0, maxWidth: 1350 }}>
        <Card>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <StyledSearch
              value={filterName}
              onChange={handleFilterByName}
              placeholder="Search Package..."
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 1100, maxWidth: "100%" }}>
              <Table size={isMobile ? "small" : "medium"}>
                <TableHead sx={{ width: "100%" }}>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        sx={{ lineHeight: "2.5rem" }}
                        key={headCell.id}
                        align="center"
                      >
                        <TableSortLabel hideSortIcon>
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...menu_packages, ...remaining_packages]
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { _id, packageId, title } = row;

                      return (
                        <>
                          <TableRow
                            sx={{ fontSize: "1rem" }}
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                                whiteSpace: "nowrap",
                              }}
                              align="center"
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1rem",
                                  whiteSpace: "nowrap",
                                }}
                                variant="subtitle2"
                                noWrap
                                align="center"
                              >
                                {title}
                              </Typography>
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1rem",
                                  whiteSpace: "nowrap",
                                }}
                                variant="subtitle2"
                                noWrap
                                align="center"
                              >
                                {packageId ? "true" : "false"}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              component="th"
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              <Button
                                disabled={disable}
                                onClick={(e) => {
                                  setDisable(true);
                                  handleEditTest(e, {
                                    id: packageId ? packageId : _id,
                                    title: title,
                                    menu,
                                    present: packageId ? true : false,
                                    type: false,
                                  });
                                }}
                              >
                                {packageId ? "Remove" : "Add"}
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={[...menu_packages, ...remaining_packages].length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
