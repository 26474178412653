import { filter } from "lodash";
import { useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Paper,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  useMediaQuery,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  styled,
  alpha,
} from "@mui/material";
import UserListHead from "../../../components/Dashboard/UserListHead";
import { Edit, Search } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import { useDispatch, useSelector } from "react-redux";

import { EyeFilled } from "@ant-design/icons";
import { usersList } from "../../../actions/userActions";

// ----------------------------------------------------------------------

const style = {
  paddingBottom: "3rem",
  position: "absolute",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 3,
};

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 45,
  margin: 20,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", align: "left" },
  { id: "mobileNumber", label: "Mobile Number", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "gender", label: "Gender", align: "left" },
  { id: "age", label: "Age", align: "left" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function Customers() {
  const isMobile = useMediaQuery("(max-width:550px)");
  const dispatch = useDispatch();

  const { isLoading, users } = useSelector((state) => state.userList);
  const customers = users.filter((u) => !(u.isEmployee || u.isAdmin));
  console.log(customers);

  useEffect(() => {
    dispatch(usersList());
  }, [dispatch]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = customers.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - customers.length) : 0;

  const filteredUsers = applySortFilter(
    customers,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers?.length && !!filterName;

  return !isLoading ? (
    <>
      <Box sx={{ m: 0, p: 0, maxWidth: 1350 }}>
        <Card>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <StyledSearch
              value={filterName}
              onChange={handleFilterByName}
              placeholder="Search Customers..."
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 1100, maxWidth: "100%" }}>
              <Table size={isMobile ? "small" : "medium"}>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={customers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { _id, name, number, email, sex, age } = row;
                      const selectedUser = selected.indexOf(_id) !== -1;
                      console.log(_id);

                      return (
                        <>
                          <TableRow
                            sx={{ fontSize: "1rem" }}
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedUser}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedUser}
                                onChange={(event) => handleClick(event, _id)}
                              />
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1rem",
                                  whiteSpace: "nowrap",
                                }}
                                variant="subtitle2"
                                noWrap
                                align="left"
                              >
                                {name}
                              </Typography>
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                                whiteSpace: "nowrap",
                              }}
                              align="left"
                            >
                              {number}
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                                whiteSpace: "nowrap",
                              }}
                              align="left"
                            >
                              {email}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                                whiteSpace: "nowrap",
                              }}
                              align="left"
                            >
                              {sex || "Not Specified"}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                                whiteSpace: "nowrap",
                              }}
                              align="left"
                            >
                              {age || "Not Specified"}
                            </TableCell>

                            <TableCell align="right">
                              <Box display="flex">
                                <IconButton size="small" color="inherit">
                                  <EyeFilled />
                                </IconButton>
                                <IconButton size="small" color="inherit">
                                  <Edit />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={customers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
