import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { activeItem } from "../../../../../components/Dashboard/store/reducers/menu";

// project import

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level, open }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  //   const menu = useSelector((state) => state.menu);
  //   const { open, openItem } = menu;

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    dispatch(activeItem({ openItem: [id] }));
  };

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: open ? "1.4rem" : "1.7rem" }} />
  ) : (
    false
  );

  //   const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  // active menu item on page load
  //   useEffect(() => {
  //     const currentIndex = document.location.pathname
  //       .toString()
  //       .split("/")
  //       .findIndex((id) => id === item.id);
  //     if (currentIndex > -1) {
  //       dispatch(activeItem({ openItem: [item.id] }));
  //     }

  //   }, []);

  const textColor = " rgb(38, 38, 38)";
  const iconSelectedColor = "rgb(24, 144, 255)";

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isSelected = pathname.includes(item.url);

  return (
    <ListItemButton
      disabled={item.disabled}
      // onClick={() => itemHandler(item.id)}
      onClick={() => navigate(item.url)}
      selected={false}
      sx={{
        zIndex: 1201,
        pl: open ? `${level * 29}px` : 1.7,
        py: !open && level === 1 ? 1.4 : 1.4,
        borderRight: isSelected ? `2px solid rgb(24, 144, 255)` : "none",
        bgcolor: isSelected ? "rgb(230, 247, 255)" : "transparent",
        ...(open && {
          "&:hover": {
            bgcolor: "rgb(230, 247, 255)",
          },
          "&.Mui-selected": {
            bgcolor: "rgb(230, 247, 255)",
            // borderRight: `2px solid rgb(24, 144, 255)`,
            color: iconSelectedColor,
            "&:hover": {
              color: iconSelectedColor,
              bgcolor: "rgb(230, 247, 255)",
            },
          },
        }),
        ...(!open && {
          "&:hover": {
            bgcolor: "transparent",
          },
          "&.Mui-selected": {
            "&:hover": {
              bgcolor: "transparent",
            },
            bgcolor: "transparent",
          },
        }),
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            ...(!open && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                bgcolor: "secondary.lighter",
              },
            }),
            ...(!open &&
              isSelected && {
                bgcolor: "rgb(230, 247, 255)",
                "&:hover": {
                  bgcolor: "primary.lighter",
                },
              }),
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      {(open || (!open && level !== 1)) && (
        <ListItemText
          primary={
            <Typography
              variant="h6"
              sx={{
                color: isSelected ? iconSelectedColor : textColor,
                fontWeight: 500,
                fontSize: "0.875rem",
                lineHeight: 1.57,
                // font-family: ""Poppins", sans-serif";
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {item.title}
            </Typography>
          }
        />
      )}
      {(open || (!open && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
