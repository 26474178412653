import { CircularProgress, Grid, Snackbar } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PopularPakages from "../../DesktopPages/PopularPakages";
import "./search.css";
import {
  Backdrop,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import { API } from "../../api";
import { searchKeyword } from "../../actions/userActions";

const style = {
  position: "absolute",
  overflowY: "auto",
  borderRadius: "15px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0.4rem",
  boxShadow: "0 0.5rem 1rem rgba(69, 69, 69, 0.5)",
};

const Search = () => {
  const navigate = useNavigate();
  // const { keyword } = useParams();
  const [filteredData, setFilteredData] = useState({});
  const matches = useMediaQuery("(min-width:550px)");
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState();
  const [message, setMessage] = useState("Book Now");
  const { search } = useSelector((state) => state.search);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(searchKeyword(keyword));
  // }, [keyword]);

  console.log(search);

  useEffect(() => {
    const srch = async () => {
      setLoading(true);
      if (search === "") {
        const t = await API.get("/test");
        const p = await API.get("/package");
        setFilteredData({ packages: p.data, tests: t.data });
      } else {
        const { data } =
          search === ""
            ? await API.get("/test")
            : await API.get(`/test/filter/${search}`);

        setFilteredData(data);
      }
      setLoading(false);
    };
    srch();
  }, [search]);

  console.log(filteredData);

  const { filter } = useSelector((state) => state.filter);
  const tests = filteredData.tests?.filter((f) =>
    f.availableIn
      .map((m) => m.stateName?.toLowerCase())
      .includes(filter?.toLowerCase())
  );
  const packages = filteredData.packages?.filter((f) =>
    f.availableIn
      .map((m) => m.stateName?.toLowerCase())
      .includes(filter?.toLowerCase())
  );
  const [state, setState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });

  const [cart, setCart] = useState(new Map());
  const { vertical, horizontal, open } = state;

  const handle = () => {
    const arr = [];
    for (const x of cart.values()) {
      arr.push(x.availableIn.map((m) => m.lab));
    }
    console.log(arr, "abcd");
    const result = arr.reduce((a, b) => a.filter((c) => b.includes(c)));
    console.log(result);
    result.length > 0
      ? navigate("/selectlab", {
          state: { data: cart, avail_labs: result },
        })
      : alert("Combination is not available");
  };
  const action = (
    <Button
      variant="contained"
      onClick={() =>
        cart.size === 1
          ? navigate("/selectlab", { state: { data: cart } })
          : handle()
      }
    >
      Show Labs
    </Button>
  );

  const addToCart = (e) => {
    if (cart.has(selected?._id)) {
      cart.delete(selected?._id);
      cart.size > 0
        ? setState({ ...state, open: true })
        : setState({ ...state, open: false });
      setMessage("Book Now");
    } else {
      var ct = cart.set(selected?._id, selected);
      setCart(ct);
      cart.size > 0
        ? setState({ ...state, open: true })
        : setState({ ...state, open: false });
      setMessage("Remove Item");
    }
  };

  const handleOpen = (s) => {
    setActive(true);
    setSelected(s);
  };

  const handleClose = () => {
    setActive(false);
    setSelected();
  };

  useEffect(() => {
    if (cart.has(selected?._id)) {
      setMessage("Remove Item");
    } else {
      setMessage("Book Now");
    }
  }, [cart, selected]);

  const filterAvailableIn = selected?.availableIn?.filter(
    (f) => f.stateName?.toLowerCase() == filter?.toLowerCase()
  );
  const discount = filterAvailableIn
    ? Math.min(...filterAvailableIn?.map((m) => m?.discountPrice))
    : 0;
  const original = filterAvailableIn
    ? Math.min(...filterAvailableIn?.map((m) => m?.originalPrice))
    : 0;

  return loading || !tests || !packages ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Box px={{ xs: 2, sm: 5, md: 8 }} py={{ xs: 1, sm: 3, md: 4 }} mb={5}>
        <Stack spacing={5}>
          <Box>
            <Stack ml={{ xs: "1rem", sm: "2rem" }}>
              <Typography fontSize="1rem" fontWeight="700" color="#616161">
                Tests
              </Typography>
              {tests?.length > 0 ? (
                <Typography fontSize="1rem" fontWeight="700" color="#616161">
                  Showing Results: {tests?.length}
                </Typography>
              ) : (
                <Typography fontSize="1rem" fontWeight="700" color="#616161">
                  No Tests Available
                </Typography>
              )}
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { xs: "center", sm: "flex-start" },
                spacing: 2,
              }}
              direction={{ xs: "column", sm: "row" }}
            >
              {tests?.length > 0 &&
                tests.map(
                  (data, index) =>
                    data.availableIn.length > 0 && (
                      <Box mx={3} key={data.title + index} p="auto">
                        <PopularPakages
                          offPercentage="30"
                          data={data}
                          type="tests"
                          cart={cart}
                          setCart={setCart}
                          state={state}
                          setState={setState}
                          handleOpen={handleOpen}
                          handleClose={handleClose}
                          message={message}
                          setMessage={setMessage}
                        />
                      </Box>
                    )
                )}
            </Stack>
          </Box>
          <Box>
            <Stack ml={{ xs: "1rem", sm: "2rem" }}>
              <Typography fontSize="1rem" fontWeight="700" color="#616161">
                Packages
              </Typography>
              {packages?.length > 0 ? (
                <Typography fontSize="1rem" fontWeight="700" color="#616161">
                  Showing Results: {packages?.length}
                </Typography>
              ) : (
                <Typography fontSize="1rem" fontWeight="700" color="#616161">
                  No Packages Available
                </Typography>
              )}
            </Stack>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { xs: "center", sm: "flex-start" },
              }}
            >
              {packages?.length > 0 &&
                packages.map((data, index) =>
                  data.availableIn.length > 0 ? (
                    <div key={data.title + index}>
                      <PopularPakages
                        offPercentage="30"
                        data={data}
                        type="packages"
                        cart={cart}
                        setCart={setCart}
                        state={state}
                        setState={setState}
                        handleOpen={handleOpen}
                        handleClose={handleClose}
                        message={message}
                        setMessage={setMessage}
                      />
                    </div>
                  ) : null
                )}
            </Box>
          </Box>
        </Stack>
      </Box>
      <div className="search">
        <Snackbar
          sx={{ marginBottom: "5rem" }}
          className="snackbar"
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          message={`${cart.size} item in Cart`}
          action={action}
          key={vertical + horizontal}
        />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={active}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{ display: "flex", justifyContent: " flex-end" }}
        >
          <Slide
            direction={matches ? "left" : "up"}
            timeout={500}
            in={active}
            mountOnEnter
            unmountOnExit
          >
            <Box
              p={{ xs: 0, sm: 2 }}
              width={{ xs: "100%", sm: 300, md: 400 }}
              height={{ xs: "70%", sm: "100%" }}
              top={{ xs: "30%", sm: "0" }}
              sx={style}
            >
              <Stack sx={{ overflowY: "auto" }} pt={3}>
                <Typography sx={{ fontSize: "1.2rem", fontWeight: "700" }}>
                  <IconButton
                    onClick={handleClose}
                    sx={{
                      // zIndex: 2,
                      padding: "5px",

                      marginRight: "1rem",
                      background: "white",
                      ":hover": { background: "white" },
                    }}
                  >
                    <NavigateBefore fontSize="large" />
                  </IconButton>
                  Product Details
                </Typography>
                <Divider />
                <Stack spacing={2} p={4}>
                  <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>
                    {selected?.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "600",
                      color: "#e57373",
                    }}
                  >
                    {selected?.requirements || "No Requirements"}
                  </Typography>
                  <Box
                    sx={{
                      height: matches ? "16rem" : "10rem",
                      overflowY: "auto",
                      display: "flex",
                      scrollBehavior: "smooth",
                      "&::-webkit-scrollbar": {
                        width: 7,
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#bdbdbd",
                        borderRadius: 2,
                      },
                    }}
                  >
                    <Typography pr={1}>{selected?.details}</Typography>
                  </Box>
                  <Divider />

                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Stack>
                      <Typography
                        sx={{ fontSize: "1.1rem", fontWeight: "700" }}
                      >
                        Rs. {discount} /-
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          fontWeight: "600",
                          textDecoration: "line-through",
                          color: "#9e9e9e",
                        }}
                      >
                        Rs. {original} /-
                      </Typography>
                    </Stack>
                    <Button variant="contained" onClick={addToCart}>
                      {message}
                    </Button>
                  </Box>
                </Stack>
                <Divider />
              </Stack>
            </Box>
          </Slide>
        </Modal>
      </div>
    </>
  );
};

export default Search;
