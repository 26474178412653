import React, { useState } from "react";
import CustomLink from "../../../components/CustomLink";
import FormHeading from "../../../components/FormHeading";
import {
  Divider,
  FormControl,
  Paper,
  Stack,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  useMediaQuery,
} from "@mui/material";
import "./AddPackage.css";
import { createPackage } from "../../../actions/packageActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddPackage = ({ handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const handleAddPackage = async (e) => {
    e.preventDefault();

    dispatch(createPackage(data, navigate));
    handleClose();
  };
  const [data, setData] = useState({
    title: "",
    details: "",
    requirements: "",
    parameters: "",
  });
  return (
    <Paper>
      <Stack p={3} mt={{ xs: 0, sm: 5 }}>
        <Typography
          variant="h5"
          sx={{ fontSize: "1.2rem", fontWeight: "700", ml: 3, mb: 3 }}
        >
          Add Test
        </Typography>
        <Divider />
        <Stack sx={{ p: { xs: 1, sm: 4 } }}>
          <FormControl>
            <Stack spacing={4}>
              <TextField
                size={matches ? "medium" : "small"}
                label="Test Title"
                variant="outlined"
                placeholder="Enter the Test Title"
                onChange={(e) => setData({ ...data, title: e.target.value })}
              />
              <TextField
                label="Test Details"
                placeholder="Enter the Details of Test"
                multiline
                rows={4}
                onChange={(e) => setData({ ...data, details: e.target.value })}
              />
              <TextField
                size={matches ? "medium" : "small"}
                label="Requirements"
                variant="outlined"
                placeholder="Enter the Requirements"
                onChange={(e) =>
                  setData({ ...data, requirements: e.target.value })
                }
              />
              <TextField
                size={matches ? "medium" : "small"}
                label="Parameters"
                variant="outlined"
                placeholder="Enter the Parameters"
                type="number"
                onChange={(e) =>
                  setData({ ...data, parameters: e.target.value })
                }
              />

              <Button
                disabled={
                  data.applicableTo === "" ||
                  data.discountPercentage === "" ||
                  data.name === "" ||
                  data.parameters === ""
                }
                variant="contained"
                onClick={handleAddPackage}
              >
                Submit
              </Button>
            </Stack>
          </FormControl>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default AddPackage;
