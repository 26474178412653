import { filter } from "lodash";
import { useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Backdrop,
  Modal,
  Fade,
  Box,
  useMediaQuery,
  Divider,
  Slide,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  styled,
  alpha,
  Stack,
} from "@mui/material";
import UserListHead from "../../../components/Dashboard/UserListHead";
import UserListToolbar from "../../../components/Dashboard/UserListToolbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Add,
  ArrowBack,
  Close,
  Edit,
  NavigateBefore,
  NavigateNext,
  Search,
} from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import { useDispatch, useSelector } from "react-redux";
// import AddTest from "./AddTest";
import { EyeFilled } from "@ant-design/icons";
import { getRadTests } from "../../../actions/radTestActions";
import AddRadTest from "./AddRadTest";

// ----------------------------------------------------------------------

const style = {
  paddingBottom: "3rem",
  position: "absolute",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 3,
};

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 45,
  margin: 20,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", align: "center" },
  { id: "details", label: "Details", align: "center" },
  { id: "parameters", label: "Parameters", align: "center" },
  { id: "originalPrice", label: "Original Price", align: "center" },
  { id: "discountPrice", label: "Discount Price", align: "center" },
  { id: "action", label: "Action", align: "center" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

// radTestList

export default function Analytics() {
  const [open, setOpen] = useState(null);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const isMobile = useMediaQuery("(max-width:550px)");
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState();

  const [selectedTest, setSelectedTest] = useState();
  const matches = useMediaQuery("(max-width:600px)");
  const { isLoading, radTestList } = useSelector((state) => state.radTests);

  useEffect(() => {
    dispatch(getRadTests());
  }, [dispatch]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpen = () => {
    setOpen1(true);
  };

  const handleClose = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen2(true);
  };

  const handleClose1 = () => {
    setOpen2(false);
    setSelectedTest(undefined);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = radTestList.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - radTestList.length) : 0;

  const filteredUsers = applySortFilter(
    radTestList,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers?.length && !!filterName;

  // name

  const navigate = useNavigate();

  return !isLoading ? (
    <>
      <Box sx={{ m: 0, p: 0, maxWidth: 1350 }}>
        <Card>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <StyledSearch
              value={filterName}
              onChange={handleFilterByName}
              placeholder="Search Test..."
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
            <Box sx={{ fontSize: "1rem", mt: 3, mr: 2 }}>
              <Button variant="contained" onClick={handleOpen}>
                <Add /> {isMobile ? "Add" : "Add Test"}
              </Button>
            </Box>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 1100, maxWidth: "100%" }}>
              <Table size={isMobile ? "small" : "medium"}>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={radTestList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        _id,
                        title,
                        details,
                        originalPrice,
                        discountPrice,
                        parameters,
                      } = row;
                      const selectedUser = selected.indexOf(_id) !== -1;
                      console.log(_id);

                      return (
                        <>
                          <TableRow
                            sx={{ fontSize: "1rem" }}
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedUser}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedUser}
                                onChange={(event) => handleClick(event, _id)}
                              />
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1rem",
                                  whiteSpace: "nowrap",
                                }}
                                variant="subtitle2"
                                noWrap
                                align="left"
                              >
                                {title}
                              </Typography>
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                                whiteSpace: "nowrap",
                              }}
                              align="left"
                            >
                              {details?.slice(0, 30)}...
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                                whiteSpace: "nowrap",
                              }}
                              align="left"
                            >
                              {parameters}
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                                whiteSpace: "nowrap",
                              }}
                              align="left"
                            >
                              {originalPrice}
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                                whiteSpace: "nowrap",
                              }}
                              align="left"
                            >
                              {discountPrice}
                            </TableCell>

                            <TableCell align="right">
                              <Box display="flex">
                                <IconButton
                                  onClick={() => {
                                    setSelectedTest(row);
                                    setOpen2(true);
                                  }}
                                  size="small"
                                  color="inherit"
                                >
                                  <EyeFilled />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  color="inherit"
                                  // onClick={(e) => {
                                  //   setSelectedId(_id);
                                  //   handleOpenMenu(e);
                                  // }}
                                >
                                  <Edit />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={radTestList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ display: "flex", justifyContent: " flex-end" }}
      >
        <Slide
          direction="left"
          timeout={500}
          in={open1}
          mountOnEnter
          unmountOnExit
        >
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", sm: 500 }}
            pr={1}
            height="100%"
            sx={style}
          >
            <Button
              sx={{
                alignSelf: "flex-end",
                m: 1.3,
                display: { xs: "flex", sm: "none" },
              }}
              onClick={handleClose}
            >
              Close
            </Button>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                display: { xs: "none", sm: "flex" },
                zIndex: 2,
                marginLeft: "-21px",
                marginTop: "1rem",
                background: "white",
                ":hover": { background: "white" },
              }}
            >
              <NavigateNext fontSize="large" />
            </IconButton>
            <AddRadTest handleClose={handleClose} />
          </Box>
        </Slide>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open2}
        onClose={handleClose1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ display: "flex", justifyContent: " flex-end" }}
      >
        <Slide
          direction="left"
          timeout={500}
          in={open2}
          mountOnEnter
          unmountOnExit
        >
          <Box
            p={{ xs: 0, sm: 2 }}
            width={{ xs: "100%", sm: 500, md: 400 }}
            height="100%"
            sx={style}
          >
            <Button
              sx={{
                alignSelf: "flex-end",
                m: 1.3,
                display: { xs: "flex", sm: "none" },
              }}
              onClick={handleClose1}
            >
              Close
            </Button>
            <IconButton
              onClick={handleClose1}
              sx={{
                position: "absolute",
                zIndex: 2,
                padding: "5px",
                marginLeft: "-35px",
                marginTop: "1rem",
                background: "white",
                ":hover": { background: "white" },
              }}
            >
              <NavigateNext fontSize="large" />
            </IconButton>
            <Stack sx={{ overflowY: "auto" }} pt={3}>
              <Typography sx={{ fontSize: "1.2rem", fontWeight: "700", ml: 4 }}>
                Test Details
              </Typography>
              <Divider />
              <Stack spacing={2} p={4}>
                <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>
                  {selectedTest?.title}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    color: "#e57373",
                  }}
                >
                  {selectedTest?.parameters || "No Parameters"}
                </Typography>
                <Box
                  sx={{
                    height: "100%",
                    maxHeight: matches ? "16rem" : "50vh",
                    overflowY: "auto",
                    display: "flex",
                    scrollBehavior: "smooth",
                    "&::-webkit-scrollbar": {
                      width: 7,
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#bdbdbd",
                      borderRadius: 2,
                    },
                    pr: "0.2rem",
                  }}
                >
                  <Typography>{selectedTest?.details}</Typography>
                </Box>

                <Divider />
                <Box display="flex" justifyContent="space-between">
                  <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>
                    Original Price: {selectedTest?.originalPrice}
                  </Typography>
                  <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>
                    Discounted Price: {selectedTest?.discountedPrice}
                  </Typography>
                </Box>
              </Stack>
              <Divider />
            </Stack>
          </Box>
        </Slide>
      </Modal>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
