import { Box, Stack, Typography } from "@mui/material";

export default function PrivacyPolicy() {
  return (
    <Box
      //   padding={4}
      width={{ xs: "75%", sm: "80%", md: "60%" }}
      //   width="978px"
      margin="1rem auto"
      padding="32px"
      backgroundColor="#f1f3f6"
      borderRadius="8px"
    >
      <Typography
        fontSize="1.5rem"
        fontWeight="bold"
        textAlign="center"
        color="#002984"
        marginBottom="1rem"
        url="https://www.apple.com/legal/images/icon_dataprivacy_2x.png"
      >
        PRIVACY POLICY
      </Typography>
      <Stack spacing={3}>
        <Typography>
          This Privacy Policy describes how we collect, use, disclose, and
          protect personal information that we receive from users of our
          diagnostic website. We understand the importance of protecting your
          privacy and we are committed to ensuring that your personal
          information is collected and processed in accordance with applicable
          data protection laws.
        </Typography>
        <Stack spacing={0}>
          <Typography sx={{ fontSize: "1.15rem", fontWeight: "700" }}>
            Information We Collect:
          </Typography>
          <Typography>
            We collect personal information that you voluntarily provide to us
            when using our website, such as your name, email address, phone
            number, and other contact information. We may also collect
            information about your computer or mobile device, such as your IP
            address, browser type, operating system, and device identifiers.
          </Typography>
        </Stack>
        <Stack spacing={0}>
          <Typography sx={{ fontSize: "1.15rem", fontWeight: "700" }}>
            Use of information:
          </Typography>
          <Typography>
            We use the information we collect to process your test bookings,
            communicate with you about your orders, and improve our services. We
            may also use your information to send you promotional emails or
            newsletters.
          </Typography>
        </Stack>
        <Stack spacing={0}>
          <Typography sx={{ fontSize: "1.15rem", fontWeight: "700" }}>
            Sharing of information:
          </Typography>
          <Typography>
            We do not sell or rent your personal information to third parties.
            However, we may share your information with our partners and service
            providers who assist us in providing our services.
          </Typography>
        </Stack>
        <Stack spacing={0}>
          <Typography sx={{ fontSize: "1.15rem", fontWeight: "700" }}>
            Security measures:
          </Typography>
          <Typography>
            We take reasonable measures to protect your personal information
            from unauthorized access or disclosure. We use SSL encryption to
            secure your information during transmission.
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
