// assets
import {
  LineChartOutlined,
  DashboardOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  LineChartOutlined,
  DashboardOutlined,
  ShoppingCartOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "group-dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "analytics",
      title: "Analytics",
      type: "item",
      url: "/dashboard/analytics",
      icon: icons.LineChartOutlined,
      breadcrumbs: false,
    },
    {
      id: "order",
      title: "Orders",
      type: "item",
      url: "/dashboard/orders",
      icon: icons.ShoppingCartOutlined,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
