export const CREATE_PACKAGE = "CREATE_PACKAGE";
export const FETCH_ALL_PACKAGES = "FETCH_ALL_PACKAGES";

export const EDIT_PACKAGE = "EDIT_PACKAGE";
export const EDIT_TEST = "EDIT_TEST";

export const CREATE_TEST = "CREATE_TEST";
export const FETCH_ALL_TESTS = "FETCH_ALL_TESTS";

export const CREATE_RAD_TEST = "CREATE_RAD_TEST";
export const FETCH_ALL_RAD_TESTS = "FETCH_ALL_RAD_TESTS";

export const CREATE_RAD_LAB = "CREATE_RAD_LAB";
export const FETCH_ALL_RAD_LABS = "FETCH_ALL_RAD_LABS";

export const CREATE_LAB = "CREATE_LAB";
export const FETCH_ALL_LABS = "FETCH_ALL_LABS";

export const CREATE_DISCOUNT = "CREATE_DISCOUNT";
export const FETCH_ALL_DISCOUNTS = "FETCH_ALL_DISCOUNTS";

export const CREATE_LOCATION = "CREATE_LOCATION";
export const FETCH_ALL_LOCATIONS = "FETCH_ALL_LOCATIONS";

export const CREATE_BANNER = "CREATE_BANNER";
export const FETCH_ALL_BANNERS = "FETCH_ALL_BANNERS";

export const CREATE_SCROLLMENU = "CREATE_SCROLLMENU";
export const FETCH_ALL_SCROLLMENUS = "FETCH_ALL_SCROLLMENUS";

export const CREATE_HEALTHISSUE = "CREATE_HEALTHISSUE";
export const FETCH_ALL_HEALTHISSUES = "FETCH_ALL_HEALTHISSUES";

export const CREATE_PRESCRIPTION = "CREATE_PRESCRIPTION";
export const FETCH_ALL_PRESCRIPTIONS = "FETCH_ALL_PRESCRIPTIONS";

export const END_LOADING = "END_LOADING";
export const START_LOADING = "START_LOADING";

export const CREATE_ORDER = "CREATE_ORDER";
export const FETCH_ALL_ORDERS = "FETCH_ALL_ORDERS";
export const FETCH_MY_ORDERS = "FETCH_MY_ORDERS";

export const FILTER = "FILTER";
