import { AddLocationAlt, Close } from "@mui/icons-material";
import React, { useState, useRef } from "react";
import "./AddUser.css";
import { useDispatch, useSelector } from "react-redux";
import { addAddress } from "../actions/userActions";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";

function AddAddress(props) {
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:550px)");

  const [address, setAddress] = useState({
    place: "",
    city: "",
    pinCode: "",
    state: "",
  });

  console.log(address);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addAddress(address));

    props.onClose();
    setAddress({ place: "", city: "", pinCode: "", state: "" });
  };
  if (!props.visibility) {
    return null;
  }
  return (
    <Stack p={4} spacing={matches ? 3 : 1}>
      <IconButton
        sx={{ bgcolor: "#e0e0e0", width: "4rem", height: "4rem", mx: "auto" }}
      >
        <AddLocationAlt sx={{ fontSize: "3rem", color: "#424242" }} />
      </IconButton>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", fontSize: "1.4rem", fontWeight: "700" }}
      >
        Enter Your Address
      </Typography>
      <Divider />
      <Stack>
        <FormControl>
          <Stack spacing={4}>
            <TextField
              size={matches ? "medium" : "small"}
              label="State"
              variant="outlined"
              placeholder="Enter your state"
              onChange={(e) =>
                setAddress((a) => ({ ...a, state: e.target.value }))
              }
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="City"
              variant="outlined"
              placeholder="Enter your city"
              onChange={(e) =>
                setAddress((a) => ({ ...a, city: e.target.value }))
              }
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Address"
              variant="outlined"
              placeholder="Enter your address"
              onChange={(e) =>
                setAddress((a) => ({ ...a, place: e.target.value }))
              }
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Pin Code"
              variant="outlined"
              placeholder="Enter your pin code"
              onChange={(e) =>
                setAddress((a) => ({ ...a, pinCode: e.target.value }))
              }
            />

            <Button
              variant="contained"
              onClick={submitHandler}
              disabled={
                !(
                  address.state &&
                  address.city &&
                  address.place &&
                  address.pinCode
                )
              }
            >
              Submit
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
  );
}

export default AddAddress;
