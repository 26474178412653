import { filter } from "lodash";
import { useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Backdrop,
  Modal,
  Fade,
  Box,
  useMediaQuery,
  Divider,
  Slide,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  styled,
  alpha,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, NavigateNext, Search } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import { useDispatch, useSelector } from "react-redux";
// import AddComplaint from "./AddComplaint";
import { getComplaints } from "../../../actions/userActions";
// import { gettests } from "../../../actions/packageActions";
import { API } from "../../../api";
import {
  editHealthissue,
  editScrollMenu,
} from "../../../actions/appearanceActions";
import { getTests } from "../../../actions/testActions";

// ----------------------------------------------------------------------

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 45,
  margin: 20,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", align: "center" },
  { id: "present", label: "Present", align: "center" },
  { id: "action", label: "Action", align: "center" },
];

// ----------------------------------------------------------------------

export default function HealthissueLabtests({ menu }) {
  const isMobile = useMediaQuery("(max-width:550px)");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getComplaints());
  }, [dispatch]);

  const { isLoading, testList } = useSelector((state) => state.tests);
  const [menuData, setMenuData] = useState({});
  const fun = async () => {
    const { data } = await API.get(`/appearance/healthissue/${menu._id}`);
    setMenuData(data);
  };
  useEffect(() => {
    fun();
    dispatch(getTests());
  }, [dispatch]);

  const menu_tests = menuData?.tests || [];
  const menu_tests_list = menu_tests?.map((d) => d.testId) || [];

  const remaining_tests = testList?.filter(
    (p) => !menu_tests_list?.includes(p._id)
  );

  const [page, setPage] = useState(0);

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleEditTest = async (e, data) => {
    e.preventDefault();

    dispatch(editHealthissue(data, navigate));
    setTimeout(() => {
      fun();
      dispatch(getTests());
    }, 500);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - [...menu_tests, ...remaining_tests].length
        )
      : 0;

  const isNotFound =
    ![...menu_tests, ...remaining_tests]?.length && !!filterName;

  // name

  const navigate = useNavigate();

  return !isLoading ? (
    <>
      <Box sx={{ m: 0, p: 0, maxWidth: 1350 }}>
        <Card>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <StyledSearch
              value={filterName}
              onChange={handleFilterByName}
              placeholder="Search Test..."
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 1100, maxWidth: "100%" }}>
              <Table size={isMobile ? "small" : "medium"}>
                <TableHead sx={{ width: "100%" }}>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        sx={{ lineHeight: "2.5rem" }}
                        key={headCell.id}
                        align="center"
                      >
                        <TableSortLabel hideSortIcon>
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...menu_tests, ...remaining_tests]
                    .filter((row) =>
                      row.title.toLowerCase().includes(filterName.toLowerCase())
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { _id, testId, title } = row;

                      return (
                        <>
                          <TableRow
                            sx={{ fontSize: "1rem" }}
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                                whiteSpace: "nowrap",
                              }}
                              align="center"
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1rem",
                                  whiteSpace: "nowrap",
                                }}
                                variant="subtitle2"
                                noWrap
                                align="center"
                              >
                                {title}
                              </Typography>
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1rem",
                                  whiteSpace: "nowrap",
                                }}
                                variant="subtitle2"
                                noWrap
                                align="center"
                              >
                                {testId ? "true" : "false"}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              component="th"
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              <Button
                                onClick={(e) =>
                                  handleEditTest(e, {
                                    id: testId ? testId : _id,
                                    title: title,
                                    menu,
                                    present: testId ? true : false,
                                    type: true,
                                  })
                                }
                              >
                                {testId ? "Remove" : "Add"}
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={[...menu_tests, ...remaining_tests].length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

// import React, { useEffect, useState } from "react";
// import "../../../App.css";
// import "../../Manage/ManagePackage/ManagePackage.css";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { getTests } from "../../../actions/testActions";
// import axios from "axios";
// import { API } from "../../../api";

// const Labtests = ({ menu }) => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const { testList } = useSelector((state) => state.tests);
//   console.log(testList);
//   const [menuData, setMenuData] = useState({});
//   const menu_tests = menuData.tests;
//   const menu_test_list = menu_tests?.map((d) => d.testId);

//   const remaining_tests = testList?.filter(
//     (p) => !menu_test_list?.includes(p._id)
//   );

//   useEffect(() => {
//     const fun = async () => {
//       const { data } = await API.get(`/appearance/scrollmenu/${menu._id}`);
//       setMenuData(data);
//     };
//     fun();
//     dispatch(getTests());
//   }, [dispatch]);
//   return (
//     <>
//       <div className="manage-package">
//         <div className="table-container">
//           <table>
//             <thead>
//               <tr>
//                 <th>Name</th>
//                 <th>Present</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {menu_tests &&
//                 menu_tests.map(({ testId, title }) => (
//                   <tr>
//                     <td>{title}</td>
//                     <td>True</td>
//                     <td
//                       onClick={() =>
//                         navigate("/dashboard/editScrollMenu", {
//                           state: {
//                             id: testId,
//                             title,
//                             menu,
//                             present: true,
//                             type: true,
//                           },
//                         })
//                       }
//                     >
//                       edit
//                     </td>
//                   </tr>
//                 ))}
//               {remaining_tests &&
//                 remaining_tests.map(({ _id, title }) => (
//                   <tr>
//                     <td>{title}</td>
//                     <td>False</td>
//                     <td
//                       onClick={() =>
//                         navigate("/dashboard/editScrollMenu", {
//                           state: {
//                             id: _id,
//                             title,
//                             menu,
//                             present: false,
//                             type: true,
//                           },
//                         })
//                       }
//                     >
//                       edit
//                     </td>
//                   </tr>
//                 ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Labtests;
