import React, { useEffect, useState } from "react";
import {
  Divider,
  FormControl,
  Paper,
  Stack,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  useMediaQuery,
} from "@mui/material";
import "../../../App.css";
import "../ManagePackage/AddPackage.css";
import "../../Manage/ManagePackage/ManagePackage.css";
import { useLocation, useNavigate } from "react-router-dom";
import CustomLink from "../../../components/CustomLink";
import FormHeading from "../../../components/FormHeading";
import { useDispatch } from "react-redux";
import { getTests } from "../../../actions/testActions";
import { editPackage, editTest } from "../../../actions/labActions";
import { getPackages } from "../../../actions/packageActions";

const EditPackage = ({ handleClose, selectedPackage }) => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const { state } = useLocation();
  const dispatch = useDispatch();
  const {
    lab,
    name,
    _id,
    title,
    details,
    parameters,
    originalPrice,
    discountPrice,
    present,
  } = selectedPackage;

  const handleEditPackage = async (e) => {
    e.preventDefault();

    dispatch(editPackage(data, navigate));
    setTimeout(() => {
      dispatch(getPackages());
    }, 400);
    handleClose();
  };
  const [data, setData] = useState({
    lab,
    name,
    _id,
    title,
    details,
    parameters,
    originalPrice,
    discountPrice,
    present,
  });

  return (
    <Paper>
      <Stack p={3} mt={2}>
        <Typography
          variant="h5"
          sx={{ fontSize: "1.2rem", fontWeight: "700", ml: 3, mb: 3 }}
        >
          Edit Package
        </Typography>
        <Divider />
        <Stack sx={{ p: 4 }}>
          <FormControl>
            <Stack spacing={4}>
              <TextField
                size={matches ? "medium" : "small"}
                value={data.title}
                label="Test Title"
                variant="outlined"
                placeholder="Enter the Test Title"
                onChange={(e) => setData({ ...data, title: e.target.value })}
              />
              <TextField
                value={data.details}
                label="Test Details"
                placeholder="Enter the Details of Test"
                multiline
                rows={3}
                onChange={(e) => setData({ ...data, details: e.target.value })}
              />
              <TextField
                value={data.parameters}
                label="Test Parameteres"
                placeholder="Enter the Parameters of Test"
                onChange={(e) =>
                  setData({ ...data, parameters: e.target.value })
                }
              />
              <TextField
                size={matches ? "medium" : "small"}
                defaultValue={data.originalPrice}
                value={data.originalPrice}
                label="Original Price"
                variant="outlined"
                type="number"
                placeholder="Enter the Original Price"
                onChange={(e) =>
                  setData({ ...data, originalPrice: e.target.value })
                }
              />
              <TextField
                size={matches ? "medium" : "small"}
                defaultValue={data.discountPrice}
                value={data.discountPrice}
                label="Discount Price"
                variant="outlined"
                type="number"
                placeholder="Enter the Discount Price"
                onChange={(e) =>
                  setData({ ...data, discountPrice: e.target.value })
                }
              />

              <Button
                disabled={
                  data.name === "" ||
                  data.details === "" ||
                  data.originalPrice === "" ||
                  data.discountPrice === ""
                }
                variant="contained"
                onClick={handleEditPackage}
              >
                Submit
              </Button>
            </Stack>
          </FormControl>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default EditPackage;

// import React, { useEffect, useState } from "react";
// import "../../../App.css";
// import "../ManagePackage/AddPackage.css";
// import "../../Manage/ManagePackage/ManagePackage.css";
// import { useLocation, useNavigate } from "react-router-dom";
// import CustomLink from "../../../components/CustomLink";
// import FormHeading from "../../../components/FormHeading";
// import { useDispatch } from "react-redux";
// import { editPackage } from "../../../actions/labActions";
// import { getPackages } from "../../../actions/packageActions";

// const EditPackage = () => {
//   const navigate = useNavigate();
//   const { state } = useLocation();
//   const dispatch = useDispatch();
// const {
//   lab,
//   name,
//   _id,
//   title,
//   details,
//   parameters,
//   originalPrice,
//   discountPrice,
//   present,
// } = state;

//   const handleEditPackage = async (e) => {
//     e.preventDefault();

//     dispatch(editPackage(data, navigate));
//     dispatch(getPackages());
//   };
//   const [data, setData] = useState({
// lab,
// name,
// _id,
// title,
// details,
// parameters,
// originalPrice,
// discountPrice,
// present,
//   });

//   return (
//     <div className="add_package">
//       <CustomLink title={"Back to Labs and packages"} />
//       <FormHeading>Edit Package</FormHeading>
//       <form onSubmit={handleEditPackage}>
//         <input
//           type="text"
//           name="title"
//           placeholder="Package Title"
//           value={data.title}
//           onChange={(e) => setData({ ...data, title: e.target.value })}
//         />
//         <textarea
//           name="detail"
//           placeholder="Package Details"
//           value={data.details}
//           onChange={(e) => setData({ ...data, details: e.target.value })}
//         />

//         <input
//           type="number"
//           name="parameters"
//           placeholder="Parameters"
//           value={data.parameters}
//           onChange={(e) => setData({ ...data, parameters: e.target.value })}
//         />
//         <input
//           type="number"
//           name="originalPrice"
//           placeholder="Original Price"
//           value={data.originalPrice}
//           onChange={(e) => setData({ ...data, originalPrice: e.target.value })}
//         />
//         <input
//           type="number"
//           name="discountPrice"
//           placeholder="Discount Price"
//           value={data.discountPrice}
//           onChange={(e) => setData({ ...data, discountPrice: e.target.value })}
//         />

//         <button type="submit" className="add_package_btn">
//           Edit Package
//         </button>
//       </form>
//     </div>
//   );
// };

// export default EditPackage;
