import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HelpIcon from "@mui/icons-material/Help";
import { HomeSharp } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Paper from "@mui/material/Paper";
import LeftDrawer from "./LeftDrawer";
import "./BottomNavigationElement.css";
import "./Header.css";
import {
  Avatar,
  Backdrop,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Slide,
  Stack,
} from "@mui/material";
import CircleImage from "./CircleImage";
import { useDispatch, useSelector } from "react-redux";
import { loginPopup, logout } from "../actions/userActions";

const style = {
  position: "absolute",
  overflowY: "auto",
  borderRadius: "15px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0.4rem",
  boxShadow: "0 0.5rem 1rem rgba(69, 69, 69, 0.5)",
};

const BottomNavigationElement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { locationList } = useSelector((state) => state.locations);
  const { userInfo } = userLogin;
  const path = useLocation().pathname;

  const handleLogout = (e) => {
    dispatch(logout());
  };

  const menuItems = [
    {
      label: "Home",
      icon: <HomeIcon />,
      onClick: () => navigate("/"),
      path: "/",
    },
    {
      label: "Bookings",
      icon: <CalendarMonthIcon />,
      onClick: () => {
        if (userInfo) navigate("/MyBooking");
        else dispatch(loginPopup(true));
      },
      path: "/MyBooking",
    },
    {
      label: "My Report",
      icon: <SummarizeRoundedIcon />,
      onClick: () => {
        if (userInfo) navigate("/MyReport");
        else dispatch(loginPopup(true));
      },
      path: "/MyReport",
    },
    {
      label: "Address",
      icon: <SupportAgentRoundedIcon />,
      onClick: () => {
        if (userInfo) navigate("/MyAddress");
        else dispatch(loginPopup(true));
      },
      path: "/MyAddress",
    },
    {
      label: "Offers",
      icon: <LocalOfferIcon />,
      onClick: () => {
        if (userInfo) navigate("/MyOffers");
        else dispatch(loginPopup(true));
      },
      path: "/MyOffers",
    },
    {
      label: "Help",
      icon: <HelpIcon />,
      onClick: () => navigate("/contact"),
      path: "/contact",
    },
  ];

  const [keyword, setKeyword] = useState("");
  const searchHandler = (e) => {
    e.preventDefault();
    if (keyword.length > 0) {
      navigate(`/search/${keyword}`);
    }
  };

  const getValue = () => {
    if (path === "/") return 0;
    else if (path === "/MyBooking") return 1;
    else if (path === "/MyReport") return 2;
    else if (path === "/contact") return 3;
    else return 4;
  };
  console.log(path);
  return (
    <div className="bottomNavigation">
      <Box sx={{ width: 400 }}>
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={getValue()}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              label="Home"
              onClick={() => {
                navigate("/");
              }}
              icon={<HomeSharp />}
            />
            <BottomNavigationAction
              label="Bookings"
              // action={navigate("/Bookings")}
              icon={<CalendarMonthRoundedIcon />}
              onClick={() => {
                if (userInfo?.number) navigate("/MyBooking");
                else dispatch(loginPopup(true));
              }}
            />
            <BottomNavigationAction
              fontSize="small"
              label="My Report"
              onClick={() => {
                if (userInfo?.number) {
                  navigate("/MyReport");
                } else dispatch(loginPopup(true));
              }}
              icon={<SummarizeRoundedIcon />}
              sx={{ minWidth: "90px" }}
              style={{ minWidth: "90px" }}
            />
            <BottomNavigationAction
              label="Support"
              onClick={() => {
                navigate("/contact");
              }}
              icon={<SupportAgentRoundedIcon />}
            />
            <BottomNavigationAction
              label="Menu"
              onClick={() => {
                setOpen(true);
              }}
              icon={<MenuIcon />}
            />
          </BottomNavigation>
        </Paper>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ display: "flex", justifyContent: " flex-end" }}
      >
        <Slide
          // direction={matches ? "down" : "left"}
          direction="left"
          timeout={500}
          in={open}
          mountOnEnter
          unmountOnExit
        >
          <Box p={{ xs: 0, sm: 2 }} width={250} height="100%" sx={style}>
            <Stack spacing={1} py={4}>
              <Box>
                <CircleImage
                  handleClick={() => {
                    dispatch(loginPopup(true));
                    setOpen(false);
                  }}
                  name={userInfo?.name}
                  number={userInfo?.number}
                />
              </Box>
              <Divider />
              <List>
                {menuItems.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{ backgroundColor: path === item.path && "#e0e0e0" }}
                    onClick={() => {
                      item.onClick();
                      setOpen(false);
                    }}
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon sx={{ mr: "-15px" }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.label} />
                    </ListItemButton>
                  </ListItem>
                ))}
                {userInfo && (
                  <ListItem onClick={handleLogout} disablePadding>
                    <ListItemButton>
                      <ListItemIcon sx={{ mr: "-15px" }}>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Logout"} />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Stack>
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

export default BottomNavigationElement;
