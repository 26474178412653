import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { AppBar, IconButton, Toolbar, useMediaQuery } from "@mui/material";

// project import
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery("(max-width:1266px)");

  const iconBackColor = "rgb(245, 245, 245)";
  const iconBackColorOpen = "#f5f5f5";

  // common header
  const mainHeader = (
    <Toolbar>
      <IconButton
        disableRipple
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        sx={{
          color: "#1565c0",
          bgcolor: "#e1f5fe",
          ml: { xs: 0, lg: open ? 3 : -2 },
          borderRadius: "5px",
          padding: "10px",
          ":hover": {
            bgcolor: "#1565c0",
            color: "#fff",
            paddingY: "8px",
          },
        }}
      >
        <MenuUnfoldOutlined style={{ fontSize: "20px" }} />
      </IconButton>
      {/* <IconButton>
        {open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton> */}
      <HeaderContent />
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      borderBottom: `1px solid #e0e0e0`,
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    },
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

export default Header;
