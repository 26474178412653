import { useEffect } from "react";
import "../../App.css";
import "../../Dashboard/Manage/ManagePackage/ManagePackage.css";
import { useDispatch, useSelector } from "react-redux";
import "./MyInfo.css";
import { getMyOrders } from "../../actions/orderActions";
import {
  Box,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { NavigateBefore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1976d2",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1976d2",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const MyBookings = () => {
  const dispatch = useDispatch();
  const { myOrders } = useSelector((state) => state.orders);
  const navigate = useNavigate();

  const steps = [
    "Order Placed",
    "Sample Collected",
    "Sample Received",
    "Report Generated",
  ];

  useEffect(() => {
    dispatch(getMyOrders());
  }, [dispatch]);
  console.log(myOrders);
  return (
    <Box
      py={{ xs: "0.5rem", sm: "1rem" }}
      px={{ xs: "1rem", sm: "4rem" }}
      bgcolor="#eeeeee"
      minHeight="80vh"
    >
      <Box
        width="95%"
        height={{ xs: "3rem", sm: "3.5rem" }}
        bgcolor="white"
        display="flex"
        alignItems="center"
        px="0.5rem"
      >
        <IconButton onClick={() => navigate("/")}>
          <NavigateBefore sx={{ color: "#0d47a1" }} />
        </IconButton>
        <Typography
          color="#0d47a1"
          fontSize={{ xs: "1.2rem", sm: "1.5rem" }}
          fontWeight="700"
          ml={3}
        >
          My Bookings
        </Typography>
      </Box>
    <div className="myBookings">
      {myOrders.length > 0 ? (

     
      myOrders.map((data, index) => (
        <div className="orderSummary_box">
          <h3 className="headingOrder">Ordered on : {new Date(data.createdAt).toString().slice(0, 15)}</h3>
          <h3 className="patientName">
            {data.orderedFor?.name}{" "}
            <span style={{ fontSize: "80%" }}>
              {data.orderedFor?.age}/{data.orderedFor?.sex}
            </span>
          </h3>
          <div className="pakageName">
            <h3>{data.orderedItem.item}</h3>
            {/* <h3>10-12hr fasting is Required</h3> */}
          </div>
          <div className="pakageAvialability">
            <p>Pickup Scheduled on</p>
            <h3>{data.orderedOn}</h3>
            <p>Sample Collection Address</p>
            <h3>
              {data.address?.place},{data.address?.city},{data.address?.state},
            </h3>
          </div>
          <Box sx={{ width: "100%", my: "1rem" }}>
            <Stepper
              connector={<QontoConnector />}
              activeStep={data.status}
              alternativeLabel
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
      ))
      ) : (
        <Typography fontSize="0.9rem" fontWeight="700" mt={1} color="#616161">
        No Bookings Available
      </Typography>
      )}
    </div>
    </Box>
  );
};

export default MyBookings;
