// assets
import { ChromeOutlined, QuestionOutlined } from "@ant-design/icons";

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
  id: "appearance",
  title: "Appearance",
  type: "group",
  children: [
    {
      id: "slideshow",
      title: "Slide Show",
      type: "item",
      url: "/dashboard/slideShow",
      icon: icons.ChromeOutlined,
    },
    {
      id: "scrollmenu",
      title: "Scroll Menu",
      type: "item",
      url: "/dashboard/scrollMenu",
      icon: icons.QuestionOutlined,
      external: true,
      target: true,
    },
    {
      id: "healthissues",
      title: "Health Issues",
      type: "item",
      url: "/dashboard/healthissues",
      icon: icons.QuestionOutlined,
      external: true,
      target: true,
    },
    {
      id: "highlightTests",
      title: "Highlight Tests",
      type: "item",
      url: "/dashboard/highlightTests",
      icon: icons.ChromeOutlined,
    },
    {
      id: "highlightPackages",
      title: "Highlight Packages",
      type: "item",
      url: "/dashboard/highlightPackages",
      icon: icons.QuestionOutlined,
      external: true,
      target: true,
    },
  ],
};

export default support;
