// assets
import { LoginOutlined, ProfileOutlined } from "@ant-design/icons";
import {
  LocalOfferOutlined,
  MedicalServicesOutlined,
  MonitorHeartOutlined,
  ScienceOutlined,
  HandshakeOutlined,
  AdminPanelSettingsOutlined,
  BadgeOutlined,
  AddLocationAltOutlined,
} from "@mui/icons-material";

// icons
const icons = {
  // LoginOutlined,
  // ProfileOutlinedOutlined,
  LocalOfferOutlined,
  MedicalServicesOutlined,
  MonitorHeartOutlined,
  ScienceOutlined,
  HandshakeOutlined,
  AdminPanelSettingsOutlined,
  BadgeOutlined,
  AddLocationAltOutlined,
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: "management",
  title: "Manage",
  type: "group",
  children: [
    {
      id: "discount",
      title: "Manage Discount",
      type: "item",
      url: "/dashboard/manageDiscount",
      icon: icons.LocalOfferOutlined,
      target: true,
    },
    {
      id: "test",
      title: "Manage Test",
      type: "item",
      url: "/dashboard/manageTest",
      icon: icons.MonitorHeartOutlined,
      target: true,
    },
    {
      id: "package",
      title: "Manage Package",
      type: "item",
      url: "/dashboard/managePackage",
      icon: icons.MedicalServicesOutlined,
      target: true,
    },
    {
      id: "partnerLabs",
      title: "Manage Partner Labs",
      type: "item",
      url: "/dashboard/managePartnerLabs",
      icon: icons.ScienceOutlined,
      target: true,
    },
    {
      id: "radiologytests",
      title: "Manage Radiology Tests",
      type: "item",
      url: "/dashboard/manageRadTests",
      icon: icons.MonitorHeartOutlined,
      target: true,
    },
    {
      id: "radiologypartners",
      title: "Manage Radiology Partners",
      type: "item",
      url: "/dashboard/manageRadPartners",
      icon: icons.HandshakeOutlined,
      target: true,
    },
    {
      id: "customers",
      title: "Manage Customers",
      type: "item",
      url: "/dashboard/manageCustomers",
      icon: icons.AdminPanelSettingsOutlined,
      target: true,
    },
    {
      id: "employees",
      title: "Manage Employees",
      type: "item",
      url: "/dashboard/manageEmployees",
      icon: icons.BadgeOutlined,
      target: true,
    },
    {
      id: "location",
      title: "Manage Location",
      type: "item",
      url: "/dashboard/manageLocation",
      icon: icons.AddLocationAltOutlined,
      target: true,
    },
  ],
};

export default pages;
