import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery } from "@mui/material";

// project import
import Drawer from "./Drawer";
import Header from "./Header";
// import navigation from 'menu-items';
// import Breadcrumbs from 'components/@extended/Breadcrumbs';

// types
import { openDrawer } from "../.././components/Dashboard/store/reducers/menu";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();

  const matchDownLG = useMediaQuery("(max-width:1100px)");

  const [open, setOpen] = useState(true);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  useEffect(() => {
    setOpen(!matchDownLG);
  }, [matchDownLG]);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          width: "100%",
          minHeight: "99vh",
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          backgroundColor: "#eef2f6",
        }}
      >
        <Toolbar />
        {/* <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} /> */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
