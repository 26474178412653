import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../../../actions/orderActions";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slide,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import UploadReports from "./UploadReports";
import ChangeStatus from "./ChangeStatus";

const style = {
  paddingBottom: "3rem",
  position: "absolute",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 3,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "customer",
    numeric: false,
    disablePadding: true,
    label: "Customer",
  },
  {
    id: "mobileNo",
    numeric: true,
    disablePadding: false,
    label: "Mobile No",
  },
  {
    id: "item",
    numeric: true,
    disablePadding: false,
    label: "Item",
  },
  {
    id: "lab",
    numeric: true,
    disablePadding: false,
    label: "Lab",
  },
  {
    id: "orderedOn",
    numeric: true,
    disablePadding: false,
    label: "Ordered On",
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "totalPrice",
    numeric: true,
    disablePadding: false,
    label: "Total Price",
  },
  {
    id: "discountUsed",
    numeric: true,
    disablePadding: false,
    label: "Discount",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "reports",
    numeric: true,
    disablePadding: false,
    label: "Reports",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ fontFamily: "Poppins, sans-serif" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const yesterday = new Date();

  yesterday.setDate(yesterday.getDate() - 1);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: "flex",
        justifyContent: "space-between",
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <FormControl sx={{ width: "9rem" }}>
          <InputLabel id="demo-simple-select-label">Orders on</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.filter}
            label="Orders on"
            onChange={(e) => props.setFilter(e.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value={new Date().toDateString()}>Today</MenuItem>
            <MenuItem value={yesterday.toDateString()}>Yesterday</MenuItem>
          </Select>
        </FormControl>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function Orders() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, orders } = useSelector((state) => state.orders);
  const [filter, setFilter] = useState("All");
  const ord = orders?.find((order) => order.userName === "Dilip");
  console.log(filter);

  const l = ["Ordered", "Shipped", "On the way", "Delivered"];

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);
  console.log(orders);

  let filteredOrders = orders;

  useEffect(() => {
    filteredOrders = orders.filter((order) => {
      console.log("called", filter);
      if (filter === "All") {
        console.log(filter);
        return order;
      } else if (
        new Date(order.createdAt).toLocaleDateString() ===
        new Date(filter).toLocaleDateString()
      ) {
        console.log(filter);
        return order;
      }
    });
    console.log(filteredOrders);
  }, [filter, orders]);

  // })

  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
    setSelectedOrder();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = orders.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty orders.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

  return !isLoading ? (
    <Box sx={{ width: "100%", fontFamily: "Poppins, sans-serif" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          filter={filter}
          setFilter={setFilter}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={
                orders.filter((order) => {
                  if (filter === "All") {
                    return order;
                  } else if (
                    new Date(order.createdAt).toLocaleDateString() ===
                    new Date(filter).toLocaleDateString()
                  ) {
                    return order;
                  }
                }).length
              }
            />
            <TableBody>
              {stableSort(
                orders.filter((order) => {
                  if (filter === "All") {
                    return order;
                  } else if (
                    new Date(order.createdAt).toLocaleDateString() ===
                    new Date(filter).toLocaleDateString()
                  ) {
                    return order;
                  }
                }),
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row._id)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1rem",
                        }}
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.userName}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1rem",
                        }}
                        align="center"
                      >
                        {row.userNo}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1rem",
                        }}
                        align="center"
                      >
                        {row.orderedItem.item}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1rem",
                        }}
                        align="center"
                      >
                        {row.orderedItem.lab}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1rem",
                        }}
                        align="center"
                      >
                        {row.orderedOn}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1rem",
                        }}
                        align="center"
                      >
                        {(
                          row.address.place +
                          " " +
                          row.address.city +
                          " " +
                          row.address.state +
                          "-" +
                          row.address.pinCode
                        ).slice(0, 20) + "..."}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1rem",
                        }}
                        align="center"
                      >
                        {row.totalPrice}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1rem",
                        }}
                        align="center"
                      >
                        {row.discountUsed || "None"}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1rem",
                        }}
                        align="center"
                        onClick={() => {
                          setOpen1(true);
                          setSelectedOrder(row);
                        }}
                      >
                        {l[row.status - 1]}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1rem",
                        }}
                        align="center"
                        onClick={() => {
                          setOpen(true);
                          setSelectedOrder(row);
                        }}
                      >
                        Add
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={
            orders.filter((order) => {
              console.log("called", filter);
              if (filter === "All") {
                console.log(filter);
                return order;
              } else if (
                new Date(order.createdAt).toLocaleDateString() ===
                new Date(filter).toLocaleDateString()
              ) {
                console.log(filter);
                return order;
              }
            }).length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open || open1}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ display: "flex", justifyContent: " flex-end" }}
      >
        <Slide
          direction="left"
          timeout={500}
          in={open || open1}
          mountOnEnter
          unmountOnExit
        >
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", sm: 500 }}
            pr={1}
            height="100%"
            sx={style}
          >
            <Button
              sx={{
                alignSelf: "flex-end",
                m: 1.3,
                display: { xs: "flex", sm: "none" },
              }}
              onClick={handleClose}
            >
              Close
            </Button>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                display: { xs: "none", sm: "flex" },
                zIndex: 2,
                marginLeft: "-21px",
                marginTop: "1rem",
                background: "white",
                ":hover": { background: "white" },
              }}
            >
              <NavigateNext fontSize="large" />
            </IconButton>
            {/* <EditPackage
              handleClose={handleClose}
              selectedTest={selectedTest}
            /> */}
            {open && (
              <UploadReports
                handleClose={handleClose}
                selectedOrder={selectedOrder}
              />
            )}
            {open1 && (
              <ChangeStatus
                handleClose={handleClose}
                selectedOrder={selectedOrder}
              />
            )}
          </Box>
        </Slide>
      </Modal>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

// import React, { useEffect } from "react";
// import "../../../App.css";
// import "../../Manage/ManagePackage/ManagePackage.css";
// import { useNavigate } from "react-router-dom";
// import { getOrders } from "../../../actions/orderActions";
// import { useDispatch, useSelector } from "react-redux";

// const Orders = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { orders } = useSelector((state) => state.orders);

//   useEffect(() => {
//     dispatch(getOrders());
//   }, [dispatch]);
//   console.log(orders);
//   const l = ["Ordered", "Shipped", "On the way", "Delivered"];

//   return (

//   );
// };

// export default Orders;
