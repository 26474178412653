import "./ListPakage.css";
import { useLocation, useNavigate } from "react-router-dom";
import DSlider from "../components/DSlider";
import PopularPakages from "./PopularPakages";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getPackages } from "../actions/packageActions";
import { getTests } from "../actions/testActions";
import { Snackbar } from "@mui/material";
import PackageCard from "../components/Cards/packageCards";
import {
  Backdrop,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { NavigateBefore } from "@mui/icons-material";

const style = {
  position: "absolute",
  overflowY: "auto",
  borderRadius: "15px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0.4rem",
  boxShadow: "0 0.5rem 1rem rgba(69, 69, 69, 0.5)",
};

const ListPakage = () => {
  const navigate = useNavigate();
  const { filter } = useSelector((state) => state.filter);
  const matches = useMediaQuery("(min-width:550px)");
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState();
  const [message, setMessage] = useState("Book Now");
  const { state } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPackages());
    dispatch(getTests());
  }, [dispatch]);
  const { packageList } = useSelector((state) => state.packages);
  const { testList } = useSelector((state) => state.tests);
  // const { testList } = useSelector((state) => state.radTests);
  console.log(testList);
  const tests = testList.filter((f) =>
    f.availableIn
      .map((m) => m.stateName?.toLowerCase())
      .includes(filter?.toLowerCase())
  );
  const packages = packageList.filter((f) =>
    f.availableIn
      .map((m) => m.stateName?.toLowerCase())
      .includes(filter?.toLowerCase())
  );
  const list = state?.name == "Health Packages" ? packages : tests;
  const [stateStatus, setStateStatus] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });

  const [cart, setCart] = useState(new Map());
  const { vertical, horizontal, open } = stateStatus;

  const handle = () => {
    const arr = [];
    for (const x of cart.values()) {
      arr.push(x.availableIn.map((m) => m.lab));
    }
    console.log(arr, "abcd");
    const result = arr.reduce((a, b) => a.filter((c) => b.includes(c)));
    console.log(result);
    result.length > 0
      ? navigate("/selectlab", {
          state: { data: cart, avail_labs: result },
        })
      : alert("Combination is not available");
  };
  const action = (
    <div
      className="checkoutButton"
      onClick={() =>
        cart.size == 1
          ? navigate("/selectlab", { state: { data: cart } })
          : handle()
      }
    >
      Show Labs
    </div>
  );

  const addToCart = (e) => {
    if (cart.has(selected?._id)) {
      cart.delete(selected?._id);
      cart.size > 0
        ? setStateStatus({ ...stateStatus, open: true })
        : setStateStatus({ ...stateStatus, open: false });
      setMessage("Book Now");
    } else {
      var ct = cart.set(selected?._id, selected);
      setCart(ct);
      cart.size > 0
        ? setStateStatus({ ...stateStatus, open: true })
        : setStateStatus({ ...stateStatus, open: false });
      setMessage("Remove Item");
    }
  };

  const handleOpen = (s) => {
    setActive(true);
    setSelected(s);
  };

  const handleClose = () => {
    setActive(false);
    setSelected();
  };

  useEffect(() => {
    if (cart.has(selected?._id)) {
      setMessage("Remove Item");
    } else {
      setMessage("Book Now");
    }
  }, [cart, selected]);

  const filterAvailableIn = selected?.availableIn?.filter(
    (f) => f.stateName?.toLowerCase() == filter?.toLowerCase()
  );
  const discount = filterAvailableIn
    ? Math.min(...filterAvailableIn?.map((m) => m?.discountPrice))
    : 0;
  const original = filterAvailableIn
    ? Math.min(...filterAvailableIn?.map((m) => m?.originalPrice))
    : 0;

  return (
    <div className="pakages_main">
      <div className="pakages_main_div">
        <h2>{state?.name}</h2>
        {state?.name === "Scan&Imaging" ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2 style={{ textAlign: "center" }}>
              "This page is currently Under Development"
            </h2>
          </div>
        ) : (
          <div className="pakages_grid">
            {list?.map((data, index) => (
              <PopularPakages
                key={index}
                offPercentage="30"
                data={data}
                cart={cart}
                setCart={setCart}
                state={stateStatus}
                setState={setStateStatus}
                handleOpen={handleOpen}
                handleClose={handleClose}
                message={message}
                setMessage={setMessage}
              />
            ))}
          </div>
        )}

        <Snackbar
          sx={{ marginBottom: "0.3rem" }}
          className="snackbar"
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          message={`${cart.size} item in Cart`}
          action={action}
          key={vertical + horizontal}
        />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={active}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ display: "flex", justifyContent: " flex-end" }}
      >
        <Slide
          direction={matches ? "left" : "up"}
          timeout={500}
          in={active}
          mountOnEnter
          unmountOnExit
        >
          <Box
            p={{ xs: 0, sm: 2 }}
            width={{ xs: "100%", sm: 300, md: 400 }}
            height={{ xs: "70%", sm: "100%" }}
            top={{ xs: "30%", sm: "0" }}
            sx={style}
          >
            <Stack sx={{ overflowY: "auto" }} pt={3}>
              <Typography sx={{ fontSize: "1.2rem", fontWeight: "700" }}>
                <IconButton
                  onClick={handleClose}
                  sx={{
                    // zIndex: 2,
                    padding: "5px",

                    marginRight: "1rem",
                    background: "white",
                    ":hover": { background: "white" },
                  }}
                >
                  <NavigateBefore fontSize="large" />
                </IconButton>
                Product Details
              </Typography>
              <Divider />
              <Stack spacing={2} p={4}>
                <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>
                  {selected?.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    color: "#e57373",
                  }}
                >
                  {selected?.requirements || "No Requirements"}
                </Typography>
                <Box
                  sx={{
                    height: matches ? "16rem" : "10rem",
                    overflowY: "auto",
                    display: "flex",
                  }}
                >
                  <Typography>{selected?.details}</Typography>
                </Box>
                <Divider />

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Stack>
                    <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>
                      Rs. {discount} /-
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        textDecoration: "line-through",
                        color: "#9e9e9e",
                      }}
                    >
                      Rs. {original} /-
                    </Typography>
                  </Stack>
                  <Button variant="contained" onClick={addToCart}>
                    {message}
                  </Button>
                </Box>
              </Stack>
              <Divider />
            </Stack>
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

export default ListPakage;
