import { Close, Person } from "@mui/icons-material";
import { useState, useRef } from "react";
import "./AddUser.css";
import gallery from "../image/gallery.png";
import { useDispatch } from "react-redux";

import axios from "axios";
import { addUser } from "../actions/userActions";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  styled,
  alpha,
  Card,
  IconButton,
} from "@mui/material";
import { API } from "../api";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: alpha("#919EAB", 0.12),

  width: "4rem",
  height: "4rem",
  borderRadius: "50%",
}));

function AddUser(props) {
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:600px)");
  const [image, setImage] = useState(null);

  const [user, setUser] = useState({
    name: "",
    age: "",
    sex: "",
    img: "",
  });

  console.log(user);

  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("file", file);

    const { data } = await API.post("/upload", formData);
    setUser({
      ...user,
      img: data[0],
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    props.setLoading(true);
    dispatch(addUser(user));
    setUser({ name: "", age: "", sex: "", img: "" });
    props.setLoading(false);
    props.onClose();
  };

  if (!props.visibility) return null;

  return (
    <Stack p={4} spacing={matches ? 3 : 1}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "baseline",
          }}
        >
          {user.img ? (
            <img
              src={user.img ? user.img : gallery}
              onClick={handleClick}
              height={70}
            ></img>
          ) : (
            <IconButton
              onClick={handleClick}
              sx={{ bgcolor: "#e0e0e0", width: "4rem", height: "4rem" }}
            >
              <Person sx={{ fontSize: "3rem", color: "#424242" }} />
            </IconButton>
          )}
          <input
            type="file"
            multiple
            ref={hiddenFileInput}
            style={{ display: "none" }}
            onChange={uploadFileHandler}
          />
        </div>
      </div>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", fontSize: "1.4rem", fontWeight: "700" }}
      >
        Enter Your Details
      </Typography>
      <Divider />
      <Stack>
        <FormControl>
          <Stack spacing={4}>
            <TextField
              size={matches ? "medium" : "small"}
              label="Name"
              variant="outlined"
              placeholder="Enter the Name"
              onChange={(e) => setUser({ ...user, name: e.target.value })}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Age"
              variant="outlined"
              type={"number"}
              placeholder="Enter the Age"
              onChange={(e) => setUser({ ...user, age: e.target.value })}
            />
            <FormControl
              size={matches ? "medium" : "small"}
              variant="outlined"
              fullWidth
            >
              <InputLabel id="select-label">Gender</InputLabel>
              <Select
                size={matches ? "medium" : "small"}
                labelId="select-label"
                id="select"
                value={user.sex}
                label="Gender"
                onChange={(e) => setUser({ ...user, sex: e.target.value })}
              >
                <MenuItem value="MALE">MALE</MenuItem>
                <MenuItem value="FEMALE">FEMALE</MenuItem>
                <MenuItem value="OTHER">OTHER</MenuItem>
              </Select>
            </FormControl>
            <Button
              disabled={user.age === "" || user.name === "" || user.sex === ""}
              variant="contained"
              onClick={submitHandler}
            >
              Submit
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
  );
}

export default AddUser;
