import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createDiscount } from "../../../actions/packageActions";
import CustomLink from "../../../components/CustomLink";
import FormHeading from "../../../components/FormHeading";
import "./AddDiscount.css";

const AddDiscount = ({ handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddDiscount = async (e) => {
    e.preventDefault();
    console.log(data);
    dispatch(createDiscount(data, navigate));
    handleClose();
  };
  const [data, setData] = React.useState({
    name: "",
    promoCode: "",
    discountPercentage: 0,
    applicableTo: "",
    hidden: "",
  });
  return (
    <Paper>
      <Stack p={3} mt={5}>
        <Typography
          variant="h5"
          sx={{ fontSize: "1.2rem", fontWeight: "700", ml: 3, mb: 3 }}
        >
          Add New Discount
        </Typography>
        <Divider />
        <Stack sx={{ p: 4 }}>
          <FormControl>
            <Stack spacing={4}>
              <TextField
                // size={matches ? "medium" : "small"}
                label="Discount Name"
                variant="outlined"
                placeholder="Enter the Discount Name"
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
              <TextField
                // size={matches ? "medium" : "small"}
                label="Promo Code"
                variant="outlined"
                placeholder="Enter the Promo Code"
                onChange={(e) =>
                  setData({ ...data, promoCode: e.target.value })
                }
              />
              <FormControl
                // size={matches ? "medium" : "small"}
                variant="outlined"
                fullWidth
              >
                <InputLabel id="select-label">Applicable To</InputLabel>
                <Select
                  // size={matches ? "medium" : "small"}
                  labelId="select-label"
                  id="select"
                  value={data.applicableTo}
                  label="Applicable To"
                  onChange={(e) =>
                    setData({ ...data, applicableTo: e.target.value })
                  }
                >
                  <MenuItem value="Any">Any</MenuItem>
                  <MenuItem value="New">New</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                // size={matches ? "medium" : "small"}
                variant="outlined"
                fullWidth
              >
                <InputLabel id="select-label">Hidden</InputLabel>
                <Select
                  // size={matches ? "medium" : "small"}
                  labelId="select-label"
                  id="select"
                  value={data.hidden}
                  label="Hidden"
                  onChange={(e) => setData({ ...data, hidden: e.target.value })}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
              <Button
                disabled={
                  data.applicableTo === "" ||
                  data.discountPercentage === "" ||
                  data.name === ""
                }
                variant="contained"
                onClick={handleAddDiscount}
              >
                Submit
              </Button>
            </Stack>
          </FormControl>
        </Stack>
      </Stack>
    </Paper>
    // <div className="add_package">
    //   <CustomLink title={"Back to Manage Discount"} />
    //   <FormHeading>Add New Discount</FormHeading>
    //   <form onSubmit={handleAddDiscount}>
    //     <input
    //       type="text"
    //       name="title"
    //       placeholder="Discount Name"
    //       onChange={(e) => setData({ ...data, name: e.target.value })}
    //     />
    //     <textarea
    //       name="promoCode"
    //       placeholder="Promo Code"
    //       onChange={(e) => setData({ ...data, promoCode: e.target.value })}
    //     />
    //     <input
    //       type="number"
    //       name="discountPercentage"
    //       placeholder="Discount Percentage"
    //       onChange={(e) =>
    //         setData({ ...data, discountPercentage: e.target.value })
    //       }
    //     />
    //     <input
    //       type="text"
    //       list="dis"
    //       placeholder="Applicable To"
    //       onChange={(e) => setData({ ...data, applicableTo: e.target.value })}
    //     />
    //     <datalist id="dis">
    //       <option>Any</option>
    //       <option>New</option>
    //     </datalist>
    //     <input
    //       type="text"
    //       list="hidd"
    //       placeholder="Hidden"
    //       onChange={(e) => setData({ ...data, hidden: e.target.value })}
    //     />
    //     <datalist id="hidd">
    //       <option>Yes</option>
    //       <option>No</option>
    //     </datalist>
    //     <button type="submit" className="add_package_btn">
    //       Add Discount
    //     </button>
    //   </form>
    // </div>
  );
};

export default AddDiscount;
