import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopularPakages from "../../DesktopPages/PopularPakages";
import "./Crousel.css";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../../actions/packageActions";
import { getTests } from "../../actions/testActions";
import { useNavigate } from "react-router-dom";
import {
  Backdrop,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import LabTestCards from "../LabTestCards";
import PackageCard from "../Cards/packageCards";

const style = {
  position: "absolute",
  overflowY: "auto",
  borderRadius: "15px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0.4rem",
  boxShadow: "0 0.5rem 1rem rgba(69, 69, 69, 0.5)",
};

const PopularPackages = ({
  cart,
  setCart,
  state,
  setState,
  handleOpen,
  handleClose,
  message,
  setMessage,
  packages,
}) => {
  console.log(packages, "called");
  return packages
    .filter((f) => f.isHighlight)
    .map((data, index) => (
      <div key={data.title + index}>
        <PopularPakages
          offPercentage="30"
          data={data}
          type="packages"
          cart={cart}
          setCart={setCart}
          state={state}
          setState={setState}
          handleOpen={handleOpen}
          handleClose={handleClose}
          message={message}
          setMessage={setMessage}
        />
      </div>
    ));
};

const Crousel = (props) => {
  const matches = useMediaQuery("(min-width:550px)");
  const { filter } = useSelector((state) => state.filter);
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow nextArrow" onClick={onClick}>
        <ArrowForwardIos />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prevArrow" onClick={onClick}>
        <ArrowBackIos />
      </div>
    );
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPackages());
    dispatch(getTests());
  }, [dispatch]);
  const navigate = useNavigate();
  const { packageList } = useSelector((state) => state.packages);
  const { testList } = useSelector((state) => state.tests);

  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState();
  const [message, setMessage] = useState("Book Now");
  const addToCart = (e) => {
    if (props.cart.has(selected?._id)) {
      props.cart.delete(selected?._id);
      props.cart.size > 0
        ? props.setState({ ...props.state, open: true })
        : props.setState({ ...props.state, open: false });
      setMessage("Book Now");
    } else {
      var ct = props.cart.set(selected?._id, selected);
      props.setCart(ct);
      props.cart.size > 0
        ? props.setState({ ...props.state, open: true })
        : props.setState({ ...props.state, open: false });
      setMessage("Remove Item");
    }
  };

  useEffect(() => {
    if (props.cart.has(selected?._id)) {
      setMessage("Remove Item");
    } else {
      setMessage("Book Now");
    }
  }, [props.cart, selected]);

  console.log(selected);

  const handleOpen = (s) => {
    setActive(true);
    setSelected(s);
  };

  const handleClose = () => {
    setActive(false);
    setSelected();
  };

  const tests = testList.filter((f) =>
    f.availableIn
      .map((m) => m.stateName?.toLowerCase())
      .includes(props?.loc?.toLowerCase())
  );
  const packages = packageList.filter((f) =>
    f.availableIn
      .map((m) => m.stateName?.toLowerCase())
      .includes(props?.loc?.toLowerCase())
  );

  console.log(packages);

  const popularTests = tests
    .filter((f, index) => f.isHighlight)
    .map((data, index) => {
      return (
        <div key={data.title + index}>
          <PopularPakages
            offPercentage="30"
            data={data}
            type="tests"
            cart={props.cart}
            setCart={props.setCart}
            state={props.state}
            setState={props.setState}
            handleOpen={handleOpen}
            handleClose={handleClose}
            message={message}
            setMessage={setMessage}
          />
        </div>
      );
    });

  // let popularPackages = [];

  const popularPackages = packages
    .filter((f) => f.isHighlight)
    .map((data, index) => {
      return (
        <div key={data.title + index}>
          <PopularPakages
            offPercentage="30"
            data={data}
            type="packages"
            cart={props.cart}
            setCart={props.setCart}
            state={props.state}
            setState={props.setState}
            handleOpen={handleOpen}
            handleClose={handleClose}
            message={message}
            setMessage={setMessage}
          />
        </div>
      );
    });

  const filterAvailableIn = selected?.availableIn?.filter(
    (f) => f.stateName?.toLowerCase() == filter?.toLowerCase()
  );
  const discount = filterAvailableIn
    ? Math.min(...filterAvailableIn?.map((m) => m?.discountPrice))
    : 0;
  const original = filterAvailableIn
    ? Math.min(...filterAvailableIn?.map((m) => m?.originalPrice))
    : 0;

  return (
    <>
      {matches ? (
        props.crousalData === "pakages" ? (
          popularPackages.length < 5 ? (
            <Stack
              direction="row"
              rowGap={4}
              spacing={6}
              justifyContent="center"
            >
              {popularPackages}
            </Stack>
          ) : (
            <Slider dotsClass="slick-dots dots-style" {...sliderSettings}>
              {popularPackages}
            </Slider>
          )
        ) : popularTests.length < 5 ? (
          <Stack direction="row" rowGap={4} spacing={6} justifyContent="center">
            {popularTests}
          </Stack>
        ) : (
          <Slider dotsClass="slick-dots dots-style" {...sliderSettings}>
            {popularTests}
          </Slider>
        )
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: { xs: "flex-start", sm: "center" },
            overflowX: "auto",
          }}
        >
          {props.crousalData == "pakages" ? popularPackages : popularTests}
        </Box>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={active}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ display: "flex", justifyContent: " flex-end" }}
      >
        <Slide
          direction={matches ? "left" : "up"}
          timeout={500}
          in={active}
          mountOnEnter
          unmountOnExit
        >
          <Box
            p={{ xs: 0, sm: 2 }}
            width={{ xs: "100%", sm: 300, md: 400 }}
            height={{ xs: "70%", sm: "100%" }}
            top={{ xs: "30%", sm: "0" }}
            sx={style}
          >
            <Stack sx={{ overflowY: "auto" }} pt={3}>
              <Typography sx={{ fontSize: "1.2rem", fontWeight: "700" }}>
                <IconButton
                  onClick={handleClose}
                  sx={{
                    // zIndex: 2,
                    padding: "5px",

                    marginRight: "1rem",
                    background: "white",
                    ":hover": { background: "white" },
                  }}
                >
                  <NavigateBefore fontSize="large" />
                </IconButton>
                Product Details
              </Typography>
              <Divider />
              <Stack spacing={2} p={4}>
                <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>
                  {selected?.title}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    color: "#e57373",
                  }}
                >
                  {selected?.requirements || "No Requirements"}
                </Typography>
                <Box
                  sx={{
                    height: matches ? "16rem" : "10rem",
                    overflowY: "auto",
                    display: "flex",
                    scrollBehavior: "smooth",
                    "&::-webkit-scrollbar": {
                      width: 7,
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#bdbdbd",
                      borderRadius: 2,
                    },
                    pr: "0.2rem",
                  }}
                >
                  <Typography>{selected?.details}</Typography>
                </Box>
                <Divider />
                {/* <Box height="10rem"></Box> */}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Stack>
                    <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>
                      Rs. {discount} /-
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        textDecoration: "line-through",
                        color: "#9e9e9e",
                      }}
                    >
                      Rs. {original} /-
                    </Typography>
                  </Stack>
                  <Button variant="contained" onClick={addToCart}>
                    {message}
                  </Button>
                </Box>
              </Stack>
              <Divider />
            </Stack>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};

export default Crousel;
