import React, { useEffect, useState } from "react";
import {
  Divider,
  FormControl,
  Paper,
  Stack,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  useMediaQuery,
} from "@mui/material";
import "../../../App.css";
import "../ManagePackage/AddPackage.css";
import "../../Manage/ManagePackage/ManagePackage.css";
import { useLocation, useNavigate } from "react-router-dom";
import CustomLink from "../../../components/CustomLink";
import FormHeading from "../../../components/FormHeading";
import { useDispatch } from "react-redux";
import { getTests } from "../../../actions/testActions";
import { editTest } from "../../../actions/labActions";

const EditTest = ({ handleClose, selectedTest }) => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const { state } = useLocation();
  const dispatch = useDispatch();
  const {
    lab,
    name,
    _id,
    title,
    details,
    originalPrice,
    discountPrice,
    present,
  } = selectedTest;

  const handleEditTest = async (e) => {
    e.preventDefault();

    dispatch(editTest(data, navigate));
    setTimeout(() => {
      dispatch(getTests());
    }, 500);
    handleClose();
  };
  const [data, setData] = useState({
    lab,
    name,
    _id,
    title,
    details,
    originalPrice,
    discountPrice,
    present,
  });

  return (
    <Paper>
      <Stack p={3} mt={2}>
        <Typography
          variant="h5"
          sx={{ fontSize: "1.2rem", fontWeight: "700", ml: 3, mb: 3 }}
        >
          Edit Test
        </Typography>
        <Divider />
        <Stack sx={{ p: 4 }}>
          <FormControl>
            <Stack spacing={4}>
              <TextField
                size={matches ? "medium" : "small"}
                value={data.title}
                label="Test Title"
                variant="outlined"
                placeholder="Enter the Test Title"
                onChange={(e) => setData({ ...data, title: e.target.value })}
              />
              <TextField
                value={data.details}
                label="Test Details"
                placeholder="Enter the Details of Test"
                multiline
                rows={6}
                onChange={(e) => setData({ ...data, details: e.target.value })}
              />
              <TextField
                size={matches ? "medium" : "small"}
                defaultValue={data.originalPrice}
                value={data.originalPrice}
                label="Original Price"
                variant="outlined"
                type="number"
                placeholder="Enter the Original Price"
                onChange={(e) =>
                  setData({ ...data, originalPrice: e.target.value })
                }
              />
              <TextField
                size={matches ? "medium" : "small"}
                defaultValue={data.discountPrice}
                value={data.discountPrice}
                label="Discount Price"
                variant="outlined"
                type="number"
                placeholder="Enter the Discount Price"
                onChange={(e) =>
                  setData({ ...data, discountPrice: e.target.value })
                }
              />

              <Button
                disabled={
                  data.name === "" ||
                  data.details === "" ||
                  data.originalPrice === "" ||
                  data.discountPrice === ""
                }
                variant="contained"
                onClick={handleEditTest}
              >
                Submit
              </Button>
            </Stack>
          </FormControl>
        </Stack>
      </Stack>
    </Paper>
    // <div className="add_package">
    //   <CustomLink title={"Back to Labs and packages"} />
    //   <FormHeading>Edit Test</FormHeading>
    //   <form onSubmit={handleEditTest}>
    //     <input
    //       type="text"
    //       name="title"
    //       placeholder="Test Title"
    //       value={data.title}
    //       onChange={(e) => setData({ ...data, title: e.target.value })}
    //     />
    //     <textarea
    //       name="detail"
    //       placeholder="Test Details"
    //       value={data.details}
    //       onChange={(e) => setData({ ...data, details: e.target.value })}
    //     />

    //     <input
    //       type="number"
    //       name="originalPrice"
    //       placeholder="Original Price"
    //       value={data.originalPrice}
    //       onChange={(e) => setData({ ...data, originalPrice: e.target.value })}
    //     />
    //     <input
    //       type="number"
    //       name="discountPrice"
    //       placeholder="Discount Price"
    //       value={data.discountPrice}
    //       onChange={(e) => setData({ ...data, discountPrice: e.target.value })}
    //     />

    //     <button type="submit" className="add_package_btn">
    //       Edit Test
    //     </button>
    //   </form>
    // </div>
  );
};

export default EditTest;
