import {
  Divider,
  FormControl,
  Paper,
  Stack,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createTest } from "../../../actions/testActions";
import CustomLink from "../../../components/CustomLink";
import FormHeading from "../../../components/FormHeading";
import "./AddTest.css";

const AddTest = ({ handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddTest = async (e) => {
    e.preventDefault();

    dispatch(createTest(data, navigate));
    handleClose();
  };
  const [data, setData] = React.useState({
    title: "",
    details: "",
    requirements: "",
  });
  return (
    <Paper>
      <Stack p={3} mt={5}>
        <Typography
          variant="h5"
          sx={{ fontSize: "1.2rem", fontWeight: "700", ml: 3, mb: 3 }}
        >
          Add Test
        </Typography>
        <Divider />
        <Stack sx={{ p: 4 }}>
          <FormControl>
            <Stack spacing={4}>
              <TextField
                // size={matches ? "medium" : "small"}
                label="Test Title"
                variant="outlined"
                placeholder="Enter the Test Title"
                onChange={(e) => setData({ ...data, title: e.target.value })}
              />
              <TextField
                label="Test Details"
                placeholder="Enter the Details of Test"
                multiline
                rows={4}
                onChange={(e) => setData({ ...data, details: e.target.value })}
              />
              <TextField
                // size={matches ? "medium" : "small"}
                label="Requirements"
                variant="outlined"
                placeholder="Enter the Requirements"
                onChange={(e) =>
                  setData({ ...data, requirements: e.target.value })
                }
              />

              <Button
                disabled={
                  data.applicableTo === "" ||
                  data.discountPercentage === "" ||
                  data.name === ""
                }
                variant="contained"
                onClick={handleAddTest}
              >
                Submit
              </Button>
            </Stack>
          </FormControl>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default AddTest;
