import React, { useState, useEffect } from "react";
import "./Booking.css";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Snackbar from "@mui/material/Snackbar";
import { useLocation, useNavigate } from "react-router-dom";
import DateCrousel from "../components/DateCrousel";
import AddUser from "./AddUser";
import AddAddress from "./AddAddress";
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "../actions/userActions";
import Avatar from "@mui/material/Avatar";
import { stringAvatar } from "../components/Avatar";
import {
  Stepper,
  Step,
  StepLabel,
  styled,
  Backdrop,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import {
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  Done,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1976d2",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1976d2",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const style = {
  position: "absolute",
  overflowY: "auto",
  borderRadius: "15px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0.4rem",
  boxShadow: "0 0.5rem 1rem rgba(69, 69, 69, 0.5)",
};

const Booking = () => {
  const loc = useLocation();
  const dispatch = useDispatch();
  const data = loc.state.info;
  const [info, setInfo] = useState(data);
  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const [addUser, setAddUser] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [address, setAddress] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  const [date, setDate] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [appointmentDetails, setAppointmentDetails] = useState({
    time: "",
  });
  const matches = useMediaQuery("(min-width:550px)");
  const { vertical, horizontal, open } = state;

  const handleClick = () => {
    // setState({ ...state, open: true });
  };

  useEffect(() => {
    if (selectedUser && address && appointmentDetails.time !== "") {
      setState({ ...state, open: true });
    }
  }, [selectedUser, address, appointmentDetails.time]);

  const handleClose = () => {
    if (appointmentDetails.time === "") setState({ ...state, open: false });
  };
  const toggleOptionsV = (e) => {
    if (e.target.parentElement.classList[0] === "appointmentSection") {
      e.target.parentElement.nextSibling.classList.toggle("hideOptions");
      e.target.parentElement.children[2].classList.toggle("rotate");
    }
  };

  const handleAppointment = (time) => {
    setAppointmentDetails({ ...appointmentDetails, time: time });

    // handleClick();
  };

  const action = (
    <Button
      onClick={() => {
        const data = {
          ...info,
          address,
          selectedUser,
          currentDate: currentDate.toString().split(" "),
          time: appointmentDetails.time,
        };
        navigate("/onSummary", { state: data });
      }}
      variant="contained"
    >
      Checkout
    </Button>
  );

  const { user } = useSelector((state) => state.userDetails);
  console.log(user);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    if (!user._id) {
      dispatch(userDetails());
    }
  }, [dispatch, navigate]);
  useEffect(() => {
    if (user.users) {
      setSelectedUser({
        name: user?.users[0].name,
        age: user?.users[0].age,
        sex: user?.users[0].sex,
      });
    }
    if (user.address && user.address.length > 0) setAddress(user.address[0]);
  }, [user]);
  // console.log(selectedUser);
  // console.log(parseInt("6:00"), date);
  // console.log(address);
  // console.log(appointmentDetails);
  const currentDate = new Date();
  const conDate = new Date();
  currentDate.setDate(conDate.getDate() + date);

  const steps = ["Find Test", "Select Lab", "Book Slot", "Checkout"];

  const isDisabled = (time, mrng) => {
    if (currentDate > new Date()) return false;
    if (mrng) {
      if (parseInt(time) < currentDate.getHours() + 1) return true;
      else return false;
    } else {
      if (parseInt(time) == 12) {
        if (currentDate.getHours() > 12) return true;
        else return false;
      } else {
        if (parseInt(time) + 12 < currentDate.getHours() + 1) return true;
        else return false;
      }
    }
  };

  return loading || !selectedUser ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        pt: "2rem",
        height: "60vh",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <div className="booking_div">
      <Box sx={{ width: "100%", pt: "2rem", pb: { xs: "0rem", sm: "2rem" } }}>
        <Stepper activeStep={2} alternativeLabel connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <h2 className="desktopElement">Select Slot and Address</h2>
      <div className="BookingFor_div">
        <div>
          <div className="bookedFor">
            <h3>Who are you Booking for?</h3>
            <Box sx={{ overflowX: "auto" }}>
              <div className="avatars">
                <Stack
                  sx={{ cursor: "pointer", alignItems: "center", ml: "1rem" }}
                  onClick={() => setAddUser(true)}
                  className="add_beforeAvtar"
                >
                  <AddCircleOutlineRoundedIcon
                    style={{
                      width: "3rem",
                      height: "3rem",
                    }}
                    fontSize="inherit"
                  />
                  <Typography
                    sx={{ fontSize: "1rem", fontWeight: "700", mt: "3px" }}
                  >
                    Add
                  </Typography>
                </Stack>
                <div className="addedAvatar">
                  {user.users &&
                    user.users.map((u) => (
                      <Stack
                        onClick={() => setSelectedUser(u)}
                        sx={{
                          cursor: "pointer",
                          border:
                            selectedUser.name === u.name &&
                            selectedUser.age === u.age &&
                            selectedUser.sex === u.sex
                              ? "2px solid #9e9e9e"
                              : "2px solid white",
                          borderRadius: "10px",
                          backgroundColor:
                            selectedUser.name === u.name &&
                            selectedUser.age === u.age &&
                            selectedUser.sex === u.sex
                              ? "#f5f5f5"
                              : "white",
                        }}
                        spacing="6px"
                        py={1}
                        px={2}
                        alignItems="center"
                        position="relative"
                      >
                        {selectedUser.name === u.name &&
                          selectedUser.age === u.age &&
                          selectedUser.sex === u.sex && (
                            <Box
                              border="2px solid #0d47a1"
                              borderRadius="50%"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              position="absolute"
                              top="2px"
                              right="2px"
                            >
                              <Done
                                sx={{
                                  color: "#0d47a1",
                                  fontSize: "1rem",
                                  fontWeight: "700",
                                }}
                              />
                            </Box>
                          )}
                        <Avatar
                          sx={{ width: "3rem", height: "3rem", mb: "3px" }}
                          {...stringAvatar(u.name)}
                        />
                        <Typography
                          fontSize={{ xs: "0.6rem", sm: "1rem" }}
                          fontWeight="600"
                          textAlign="center"
                          height="27px"
                          sx={{
                            mt: "3px",
                          }}
                        >
                          {u.name}
                        </Typography>
                      </Stack>
                    ))}
                </div>
              </div>
            </Box>
          </div>
          <div className="pickUpLocation">
            <h3>Pick Up Location</h3>
            <Box sx={{ overflowX: "auto" }}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: { xs: "nowrap", sm: "wrap" },
                  pl: { xs: "0.7rem", sm: "0rem" },
                }}
              >
                {user.address &&
                  user.address.map((add, index) => (
                    <Stack
                      onClick={() => setAddress(add)}
                      sx={{
                        cursor: "pointer",
                        border:
                          address.place === add.place
                            ? "2px solid #9e9e9e"
                            : "2px solid #e0e0e0",
                        borderRadius: "10px",
                        backgroundColor:
                          address.place === add.place ? "#f5f5f5" : "white",
                        minWidth: "170px",
                        minHeight: "110px",
                      }}
                      position="relative"
                      spacing="6px"
                      py={1}
                      px={2}
                      mx={1}
                      mb={2}
                      textAlign="center"
                    >
                      {address.place === add.place && (
                        <Box
                          border="2px solid #0d47a1"
                          borderRadius="50%"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          position="absolute"
                          top="2px"
                          right="2px"
                        >
                          <Done
                            sx={{
                              color: "#0d47a1",
                              fontSize: "1rem",
                              fontWeight: "700",
                            }}
                          />
                        </Box>
                      )}
                      <Typography fontSize="1rem" fontWeight="700">
                        Address {index + 1}
                      </Typography>
                      <Typography mb={2} fontSize="0.75rem">
                        {add.place.slice(0, 27)} . . .
                      </Typography>
                      <Typography fontSize="0.75rem">
                        {add.city}-{add.pinCode}
                      </Typography>
                      <Typography fontSize="0.75rem">{add.state}</Typography>
                    </Stack>
                  ))}

                <Stack
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "2px solid #9e9e9e",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    minWidth: "130px",
                    minHeight: "110px",
                  }}
                  py={1}
                  px={2}
                  mx={1}
                  onClick={() => setAddAddress(true)}
                  mb={2}
                >
                  <LocationOnIcon fontSize="large" color="inherit" />
                  <div
                    onClick={() => setAddAddress(true)}
                    className="addAddressButton"
                  >
                    Add Address
                  </div>
                </Stack>
              </Box>
            </Box>
          </div>
        </div>
        <div>
          <div className="selectBookingSlot">
            <h3>Select Booking Slot</h3>
            <div className="selectBookingSlot_div">
              <DateCrousel setdate={(d) => setDate(d)} />
              <div className="appointmentSelect">
                <div className="appointmentSection" onClick={toggleOptionsV}>
                  <h4>Morning </h4>
                  <span
                    style={{
                      fontSize: "90%",
                      fontWeight: "600",
                      margin: "2px",
                    }}
                  >
                    (6:00 AM - 12:00 PM)
                  </span>

                  <ArrowDropDownIcon className="downArrow" fontSize="large" />
                </div>

                <div className="appointmentOptions hideOptions">
                  {[
                    "6:00AM - 7:00AM",
                    "7:00AM - 8:00AM",
                    "8:00AM - 9:00AM",
                    "9:00AM - 10:00AM",
                    "10:00AM - 11:00AM",
                    "11:00AM - 12:00PM",
                  ].map((time) => (
                    <Button
                      sx={{
                        px: 0,
                        m: "0.5rem",
                        width: "8rem",
                        fontWeight: "bold",
                      }}
                      size="small"
                      variant={
                        appointmentDetails.time == time
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => handleAppointment(time)}
                      disabled={isDisabled(time, true)}
                    >
                      {time}
                    </Button>
                  ))}
                </div>
              </div>
              <div className="appointmentSelect">
                <div className="appointmentSection" onClick={toggleOptionsV}>
                  <h4>Afternoon</h4>
                  <span
                    style={{
                      fontSize: "90%",
                      fontWeight: "600",
                      margin: "2px",
                    }}
                  >
                    (12:00 PM-5:00PM)
                  </span>
                  <ArrowDropDownIcon className="downArrow" fontSize="large" />
                </div>

                <div className="appointmentOptions hideOptions">
                  {[
                    "12:00PM - 1:00PM",
                    "1:00PM - 2:00PM",
                    "2:00PM - 3:00PM",
                    "3:00PM - 4:00PM",
                    "4:00PM - 5:00PM",
                  ].map((time) => (
                    <Button
                      sx={{
                        px: 0,
                        m: "0.5rem",
                        width: "8rem",
                        fontWeight: "bold",
                      }}
                      size="small"
                      variant={
                        appointmentDetails.time == time
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => handleAppointment(time)}
                      disabled={isDisabled(time, false)}
                    >
                      {time}
                    </Button>
                  ))}
                </div>
              </div>
              <Snackbar
                sx={{ marginBottom: "5rem" }}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                message="Slot Selected"
                action={action}
                key={vertical + horizontal}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={addUser || addAddress}
        onClose={() => {
          setAddUser(false);
          setAddAddress(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ display: "flex", justifyContent: " flex-end" }}
      >
        <Slide
          // direction={matches ? "down" : "left"}
          direction={matches ? "left" : "up"}
          timeout={500}
          in={addUser || addAddress}
          mountOnEnter
          unmountOnExit
        >
          <Box
            p={{ xs: 0, sm: 2 }}
            width={{ xs: "100%", sm: 300, md: 400 }}
            height={{ xs: "70%", sm: "100%" }}
            top={{ xs: "30%", sm: "0" }}
            sx={style}
          >
            <IconButton
              onClick={() => {
                setAddUser(false);
                setAddAddress(false);
              }}
              sx={{ position: "absolute", top: 0, right: 0, zIndex: 3 }}
              className="wrong-icon"
            >
              <Close />
            </IconButton>
            <AddUser
              setLoading={setLoading}
              visibility={addUser}
              onClose={() => setAddUser(false)}
            />
            <AddAddress
              visibility={addAddress}
              onClose={() => setAddAddress(false)}
            />
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

export default Booking;
