import { filter } from "lodash";
import { useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Backdrop,
  Modal,
  Fade,
  Box,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Drawer,
  Slide,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  styled,
  alpha,
} from "@mui/material";
import UserListHead from "../../../components/Dashboard/UserListHead";
import UserListToolbar from "../../../components/Dashboard/UserListToolbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Add,
  ArrowBack,
  Close,
  Edit,
  NavigateBefore,
  NavigateNext,
  Search,
} from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { getDiscounts } from "../../../actions/packageActions";
import AddDiscount from "./AddDiscount";

// ----------------------------------------------------------------------

const style = {
  paddingBottom: "3rem",
  position: "absolute",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 3,
};

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 45,
  margin: 20,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", align: "center" },
  { id: "promocode", label: "Promo Code", align: "center" },
  { id: "discountPercentage", label: "Discount Percentage", align: "center" },
  { id: "applicableto", label: "Applicable To", align: "center" },
  // { id: 'gender', label: 'Gender', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

// discountList

export default function Analytics() {
  const [open, setOpen] = useState(null);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const isMobile = useMediaQuery("(max-width:550px)");
  const [student, setStudent] = useState();
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState();
  const { discountList } = useSelector((state) => state.discounts);
  useEffect(() => {
    dispatch(getDiscounts());
  }, [dispatch]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleOpen = () => {
    setOpen1(true);
  };

  const handleClose = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen2(true);
  };

  const handleClose1 = () => {
    setOpen2(false);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = discountList.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleClick1 = () => {};

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - discountList.length) : 0;

  const filteredUsers = applySortFilter(
    discountList,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers?.length && !!filterName;

  const navigate = useNavigate();
  console.log(selectedId);

  return discountList ? (
    <>
      <Container sx={{ m: 0, p: 0 }}>
        <Card sx={{ maxWidth: "1240px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <StyledSearch
              value={filterName}
              onChange={handleFilterByName}
              placeholder="Search Discount..."
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
            <Box sx={{ fontSize: "1rem", mt: 3, mr: 2 }}>
              <Button variant="contained" onClick={handleOpen}>
                <Add /> {isMobile ? "Add" : "Add Discount"}
              </Button>
            </Box>
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 1100 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={discountList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        _id,
                        name,
                        promoCode,
                        discountPercentage,
                        applicableTo,
                      } = row;
                      const selectedUser = selected.indexOf(_id) !== -1;
                      console.log(_id);

                      return (
                        <>
                          <TableRow
                            sx={{ fontSize: "1rem" }}
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedUser}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedUser}
                                onChange={(event) => handleClick(event, _id)}
                              />
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1rem",
                                }}
                                variant="subtitle2"
                                noWrap
                                align="center"
                              >
                                {name}
                              </Typography>
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              align="center"
                            >
                              {promoCode}
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              align="center"
                            >
                              {discountPercentage}
                            </TableCell>

                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              align="center"
                            >
                              {applicableTo}
                            </TableCell>

                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                // onClick={(e) => {
                                //   setSelectedId(_id);
                                //   handleOpenMenu(e);
                                // }}
                              >
                                <Edit />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={discountList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ display: "flex", justifyContent: " flex-end" }}
      >
        <Slide
          direction="left"
          timeout={500}
          in={open1}
          mountOnEnter
          unmountOnExit
        >
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", sm: 500 }}
            pr={1}
            height="100%"
            sx={style}
          >
            <Button
              sx={{
                alignSelf: "flex-end",
                m: 1.3,
                display: { xs: "flex", sm: "none" },
              }}
              onClick={handleClose}
            >
              Close
            </Button>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                display: { xs: "none", sm: "flex" },
                zIndex: 2,
                marginLeft: "-21px",
                marginTop: "1rem",
                background: "white",
                ":hover": { background: "white" },
              }}
            >
              <NavigateNext fontSize="large" />
            </IconButton>
            <AddDiscount handleClose={handleClose} />
          </Box>
        </Slide>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open2}
        onClose={handleClose1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ display: "flex", justifyContent: " flex-end" }}
      >
        <Slide
          direction="left"
          timeout={500}
          in={open2}
          mountOnEnter
          unmountOnExit
        >
          <Box
            p={{ xs: 0, sm: 2 }}
            width={{ xs: "100%", sm: 500, md: 400 }}
            height="100%"
            sx={style}
          >
            <Button
              sx={{
                alignSelf: "flex-end",
                m: 1.3,
                display: { xs: "flex", sm: "none" },
              }}
              onClick={handleClose1}
            >
              Close
            </Button>
            <IconButton
              onClick={handleClose1}
              sx={{
                position: "absolute",
                zIndex: 2,
                padding: "5px",
                marginLeft: "-35px",
                marginTop: "1rem",
                background: "white",
                ":hover": { background: "white" },
              }}
            >
              <NavigateNext fontSize="large" />
            </IconButton>
          </Box>
        </Slide>
      </Modal>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
