import React, { useState } from "react";
import {
  Divider,
  FormControl,
  Stack,
  Typography,
  TextField,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createRadTest } from "../../../actions/radTestActions";
import "./AddRadTest.css";

const AddRadTest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const handleAddRadTest = async (e) => {
    e.preventDefault();

    dispatch(createRadTest(data, navigate));
  };
  const [data, setData] = useState({
    title: "",
    details: "",
    originalPrice: "",
    parameters: "",
    discountPrice: "",
  });
  return (
    <Stack p={4} spacing={matches ? 3 : 1}>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", fontSize: "1.4rem", fontWeight: "700" }}
      >
        Add Radiology Tests
      </Typography>
      <Divider />
      <Stack>
        <FormControl>
          <Stack spacing={4}>
            <TextField
              size={matches ? "medium" : "small"}
              label="Title"
              variant="outlined"
              placeholder="Enter the Title"
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
            <TextField
              label="Test Details"
              placeholder="Enter the Details of Test"
              multiline
              rows={4}
              onChange={(e) => setData({ ...data, details: e.target.value })}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Parameters"
              variant="outlined"
              placeholder="Enter the Parameters"
              onChange={(e) => setData({ ...data, parameters: e.target.value })}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Original Price"
              variant="outlined"
              type={"number"}
              placeholder="Enter the Original Price"
              onChange={(e) =>
                setData({ ...data, originalPrice: e.target.value })
              }
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Discount Price"
              variant="outlined"
              type={"number"}
              placeholder="Enter the Discount Price"
              onChange={(e) =>
                setData({ ...data, discountPrice: e.target.value })
              }
            />
            <Button
              disabled={
                data.title === "" ||
                data.parameters === "" ||
                data.details === "" ||
                data.originalPrice === "" ||
                data.discountPrice === ""
              }
              variant="contained"
              onClick={handleAddRadTest}
            >
              Submit
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
    // <div className='add_package'>
    //   <CustomLink title={'Back to Manage Radiology Tests'} />
    //   <FormHeading>Add New Radiology Test</FormHeading>
    //   <form onSubmit={handleAddRadTest}>
    //     <input
    //       type='text'
    //       name='title'
    //       placeholder='test Title'
    //       onChange={(e) => setData({ ...data, title: e.target.value })}
    //     />
    //     <textarea
    //       name='detail'
    //       placeholder='test Details'
    //       onChange={(e) => setData({ ...data, details: e.target.value })}
    //     />
    //     <input
    //       type='number'
    //       name='originalPrice'
    //       placeholder='Original Price'
    //       onChange={(e) => setData({ ...data, originalPrice: e.target.value })}
    //     />
    //     <input
    //       type='number'
    //       name='parameters'
    //       placeholder='Parameters'
    //       onChange={(e) => setData({ ...data, parameters: e.target.value })}
    //     />
    //     <input
    //       type='number'
    //       name='discountPrice'
    //       placeholder='Discount Price'
    //       onChange={(e) => setData({ ...data, discountPrice: e.target.value })}
    //     />
    //     <button type='submit' className='add_package_btn'>
    //       Add Radiology Test
    //     </button>
    //   </form>
    // </div>
  );
};

export default AddRadTest;
