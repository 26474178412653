// material-ui
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

const openedMixin = (theme) => ({
  width: 300,
  borderRight: `1px solid #fafafa`,
  transition: "width 500ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  overflowX: "hidden",
  boxShadow: "none",
  overflowY: "hidden",
});

const closedMixin = (theme) => ({
  transition: "width 500ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  overflowX: "hidden",
  width: 0,
  borderRight: "none",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
});

// ==============================|| DRAWER - MINI STYLED ||============================== //

const MiniDrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 260,

  //   backgroundColor: "black",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  // backgroundColor: "black",
}));

export default MiniDrawerStyled;
