import {
  AddIcCallOutlined,
  MedicationOutlined,
  FeedbackOutlined,
} from "@mui/icons-material";

// icons
const icons = {
  AddIcCallOutlined,
  MedicationOutlined,
  FeedbackOutlined,
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: "Healthexpert",
  title: "Health Expert",
  type: "group",
  children: [
    {
      id: "callrequest",
      title: "Call Request",
      type: "item",
      url: "/dashboard/callrequest",
      icon: icons.AddIcCallOutlined,
    },
    {
      id: "preseciption",
      title: "Prisciption Upload",
      type: "item",
      url: "/dashboard/prescriptionUploaded",
      icon: icons.MedicationOutlined,
    },
    {
      id: "customercomplaints",
      title: "Customer Complaints",
      type: "item",
      url: "/dashboard/customerComplaints",
      icon: icons.FeedbackOutlined,
    },
  ],
};

export default utilities;
