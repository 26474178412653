import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createLab } from "../../../actions/labActions";
import { API } from "../../../api";
import "./AddLab.css";

const AddLab = ({ handleClose, locationList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddLab = async (e) => {
    e.preventDefault();

    dispatch(createLab(data, navigate));
    handleClose();
  };
  const matches = useMediaQuery("(min-width:600px)");

  const [imgName, setImgName] = useState("");
  const uploadFileHandler = async (e) => {
    setImgName(e.target.files[0].name);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    const { data: info } = await API.post("/upload", formData);
    console.log(info[0]);
    setData({
      ...data,
      logo: info[0],
    });
  };
  const [data, setData] = useState({
    title: "",
    logo: "",
    accrediation: "",
    time: "",
    state: "",
  });
  return (
    <Stack p={4} spacing={matches ? 3 : 1}>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", fontSize: "1.4rem", fontWeight: "700" }}
      >
        Add Partner Labs
      </Typography>
      <Divider />
      <Stack>
        <FormControl>
          <Stack spacing={4}>
            <TextField
              size={matches ? "medium" : "small"}
              label="Title"
              variant="outlined"
              placeholder="Enter the Title"
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Accrediation"
              variant="outlined"
              placeholder="Enter the Accrediation"
              onChange={(e) =>
                setData({ ...data, accrediation: e.target.value })
              }
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Time"
              variant="outlined"
              type={"number"}
              placeholder="Enter the Time"
              onChange={(e) => setData({ ...data, time: e.target.value })}
            />
            <FormControl
              size={matches ? "medium" : "small"}
              variant="outlined"
              fullWidth
            >
              <InputLabel id="select-label">City</InputLabel>
              <Select
                size={matches ? "medium" : "small"}
                labelId="select-label"
                id="select"
                label="State"
                onChange={(e) => setData({ ...data, state: e.target.value })}
              >
                {locationList.map((item) => (
                  <MenuItem value={item.city}>{item.city}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box display="flex" alignItems="center">
              <Button variant="contained" component="label" sx={{ mr: 5 }}>
                Upload
                <input
                  onChange={uploadFileHandler}
                  style={{ display: "none" }}
                  type="file"
                  name="bannerImage"
                  id="bannerImage"
                />
              </Button>
              {data.logo && (
                <img
                  src={`https://server.gkdiagnostic.com/${data.logo}`}
                  height={50}
                  alt="not found"
                />
              )}
            </Box>

            <Button
              disabled={
                data.title === "" ||
                data.accrediation === "" ||
                data.time === "" ||
                data.state === "" ||
                data.logo === ""
              }
              variant="contained"
              onClick={handleAddLab}
            >
              Submit
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default AddLab;
